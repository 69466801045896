@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.Inner {
  padding: 36px 40px;

  @media (max-width: 767px) {
    padding: 20px;
  }

  @media (max-width: 585px) {
    padding: 14px;
  }

  .ant-col-lg-8 {
    margin-bottom: 24px;
  }
}

.Outer {
  margin: 30px 40px 30px 50px;
  box-shadow: 0px 0px 12px #00000012;

  @media (max-width: 767px) {
    margin: 20px;
  }
}

.Security {
  &_flex {
    justify-content: space-between;
    align-items: center;
    background-color: var(--settingCard);
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 18px 20px;
    grid-gap: 10px;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &_icon {
      background: #f1eff5 0% 0% no-repeat padding-box;
      border: 1px solid #e6e0f1;
      border-radius: 8px;
      height: 44px;
      width: 44px;
      display: inline-block;
      padding: 10px;
      margin-right: 15px;

      img {
        width: 23px;
        height: 23px;
      }
    }

    h6 {
      @include fluid-type(320px, 1920px, 12px, 16px);
      font-weight: 600;
      color: var(--headerText);
    }

    .btn40 {
      height: 40px;
      width: 120px;
      @include fluid-type(320px, 1920px, 12px, 16px);

      &.active {
        background-color: $bg-light-gray-F3F3F3;
        border: 1px solid $bordergrey;
        color: $baseblue;
        position: relative;
      }
    }

    &_btn {
      @media (max-width: 991px) {
        margin-top: 20px;
        margin-left: auto;
      }
    }
  }
}

.settingPage {
  position: relative;
  margin: 30px 40px 30px 50px;

  // @media (max-width:1200px) {
  //   margin-top: 70px;
  // }
  @media (max-width:676px) {
    margin: 30px 20px 30px 20px;
  }

  &_notFund {
    text-align: center;
    margin-top: 16px;
    @include fluid-type(320px, 1920px, 14px, 20px);
    color: $text-80838E;
    padding: 100px 0;
  }

  &_settingtabs {
    position: relative;
  }

  &_verifiedBtn {
    position: absolute;
    top: 20px;
    display: none;
    right: 30px;

    @media (max-width: 1024px) {
      top: 70px;
    }

    @media (max-width: 767px) {
      left: 30px;
      right: unset;
    }

    button {
      background: $bg-light-gray-F3F3F3;
      border-radius: 25px;
      opacity: 1;
      padding: 0px 30px;
      @include fluid-type(320px, 1920px, 14px, 16px);
      height: 40px;
      border: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 4px;
    }
  }

  &_findsec {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 2;
  }
}

.find_filter_search {
  button {
    height: 40px;
  }
}

.Notifications {
  border: 1px solid $bgderkgrey;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;

  h6 {
    font-weight: 600;
  }
}

// order table css in setting tabs
.order_side {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.order_type {
  color: "white";
  width: "fit-content";
  height: "30px";
  padding: "4px";
  min-width: "40px";
  border-radius: "10px";
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

@media (max-width: 767px) {

  // .buyTable {
  //   table {
  //     td,
  //     th {
  //       &:nth-child(2),
  //       &:nth-child(3),
  //       &:nth-child(5),
  //       &:nth-child(6),
  //       &:nth-child(7),
  //       &:nth-child(9) {
  //         display: none;
  //       }
  //     }
  //   }
  // }
  .convertTable {
    table {

      td,
      th {
        &:last-child {
          display: none;
        }
      }
    }
  }

  // .sendTable {
  //   table {

  //     td,
  //     th {

  //       // &:nth-child(3),
  //       &:nth-child(4),
  //       &:nth-child(6) {
  //         display: none;
  //       }
  //     }
  //   }
  // }

  // .orderTable {
  //   table {

  //     td,
  //     th {

  //       &:nth-child(3),
  //       &:nth-child(5),
  //       &:nth-child(7),
  //       &:nth-child(8),
  //       &:nth-child(9),
  //       &:nth-child(10) {
  //         display: none;
  //       }
  //     }
  //   }
  // }

  // .tradeTable {
  //   table {

  //     td,
  //     th {

  //       &:nth-child(4),
  //       &:nth-child(5),
  //       &:nth-child(7) {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}

.profileImg {
  display: flex;
  align-items: center;
  gap: 22px;

  h2 {
    color: var(--headerText);

    @include fluid-type(320px, 1920px, 16px, 24px);
    font-weight: 600;
  }
}

.leftButton {
  b {
    color: var(--headerText);

  }

  p {
    color: $red;
    ;
  }
}

.deviceHeading {
  @include fluid-type(320px, 1920px, 16px, 20px);
  font-weight: 600;
  color: var(--headerText);
  padding-bottom: 24px;
}

.updateProfile {
  background: transparent linear-gradient(180deg, #ffffff 0%, #e6faff 100%) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 32px;

  .update {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .changename {
    h2 {
      @include fluid-type(320px, 1920px, 20px, 24px);
      color: $darktext;
      margin-bottom: 10px !important;
    }

    h4 {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: $darktext;
      margin: 20px 0 10px 0;
      padding-bottom: 8px;
    }

    .btns {
      margin-top: 36px;
      width: 100%;
    }
  }

  img {
    padding: 58px 102px 26px 102px;
  }

  p {
    padding-bottom: 50px;
    color: $darktext;
    font-weight: 600;
    max-width: 263px;
    @include fluid-type(320px, 1920px, 14px, 16px);
    text-align: center;
  }

  .btn {
    width: 100%;
    max-width: 263px;
    margin-bottom: 18px;
  }

  .btnsubmit {
    width: 100%;
  }

  .data {
    width: 100%;
    font-weight: 100%;
  }

  .secverification {
    text-align: left;
    width: 100%;

    h1 {
      @include fluid-type(320px, 1920px, 20px, 24px);
      font-weight: 600;
      color: $darktext;
      padding-bottom: 20px;
    }
  }
}

.upperSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}