@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./Components/Assets/Styles/_mixins.scss";
@import "./Components/Assets/Styles/_vars.scss";

.theme-dark {
  --dark-text: #292929;
  --light-text: #f9f8f8;
  --dark-background: #141c2b;
  --light-background: #586f7c;
  --accent: #b8dbd9;
  --themebgbtn: transparent linear-gradient(170deg, #392765 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;
  --button-border: #b8dbd9;
  --main-bg: #030303;
  --sidebar-bg: #141c2b;
  --selcted-Tab: transparent linear-gradient(167deg, #392765 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;

  --seetingCardBg: #1f2638;
  --headerText: #ffffff;
  --headerText1: #ffffff;
  --sidebariconbg: #fff;
  --themegraybtn: #1f2638;
  --tradebg: #141c2b;
  --btntext: #ffffff;
  --emptytext: #ffffff;
  --settingCard: #111624;
  --setingCardBg: #1f2638;
  --bordercolor: #ffffff33;
  --homeCard: #111624;
  --whiteblack: #fff;
  --smalp: #ffffff;
  --bgusdtcard: #111624;
  --gridCard: #1f2638;
  --collpasedata: #1f2638;
  --cardBackground: #1f2638;
  --textColor-80839E: #fff;
  --chartCardBG: #111624;
  --hoverWhite: #f3f2f636;
  --shadowWhite: 0 3px 6px -4px rgba(26, 25, 25, 0.12),
    0 6px 16px 0 rgba(145, 133, 133, 0.08),
    0 9px 28px 8px rgba(176, 169, 169, 0.05);
  --shadowifram: 0 3px 6px -4px rgba(135, 131, 131, 0.12),
    0 6px 16px 0 rgba(145, 133, 133, 0.08),
    0 9px 28px 8px rgba(237, 232, 232, 0.1);
  --modalBg: #111624 !important;
  --cardbgDCEAEF: #111624;
  --sidebarborder: #4d4c4c4f;
  --tabletext: #fcfcfc;
  --bankcardBg: rgba(31, 30, 30, 0.5);
  --searchinputclr: #1f2638;
  --borderclr: #ffffff33;
  --sidebarbrdr: #ffffff33;
  --pricevolumeBG: rgb(67, 73, 85);
  --cardsec: #1f2638;
  --shadow: 0px 0px 12px #ffffff12;
}

.theme-light {
  --dark-text: #5e4b56;
  --light-text: #5e4b56;
  --btntext: #392765;
  --homeCard: #fff;
  --hoverWhite: #f3f2f6;
  --themebgbtn: linear-gradient(166deg,
      rgba(43, 100, 136, 1) 0%,
      rgba(30, 160, 171, 1) 100%);
  --themegraybtn: #f1eff5;
  --bordercolor: #d7d4e0;
  --dark-background: transparent linear-gradient(180deg, #ffffff 0%, #dff0f000 100%) 0% 0% no-repeat padding-box;
  --seetingCardBg: transparent linear-gradient(180deg, #fdfcff 0%, #ffffffba 100%) 0% 0% no-repeat padding-box;
  --light-background: #eddcd2;
  --sidebariconbg: transparent linear-gradient(156deg, #392765 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;
  --accent: #dbe7e4;
  --button-border: #5e4b56;
  --bgusdtcard: #fbfbfb;
  --main-bg: linear-gradient(72deg,
      rgba(254, 254, 254, 1) 25%,
      rgba(219, 238, 235, 1) 100%);
  --sidebar-bg: transparent linear-gradient(179deg, #392765 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;
  --selcted-Tab: #fff;
  --headerText: #392765;
  --headerText1: #1ea0ab;
  --settingCard: #f6f5f9;
  --setingCardBg: transparent linear-gradient(180deg, #fdfcff 0%, #ffffffba 100%) 0% 0% no-repeat padding-box;
  --homeCard: #fefdfe;
  --tradebg: #fff;
  --whiteblack: #181e25;
  --smalp: #897ea3;
  --gridCard: #3927650f;
  --collpasedata: #eeedf1;
  --cardBackground: #ffffffff;
  --chartCardBG: #f0eff4;
  --textColor-80839E: #80839c;
  --shadowWhite: 0px 3px 7px #00000017;
  --shadowifram: 0px 3px 7px #00000017;
  --modalBg: transparent linear-gradient(180deg, #ffffff 0%, #e6faff 100%) 0% 0% no-repeat padding-box;
  --cardbgDCEAEF: #dceaef;
  --tabletext: #1b1c1e;
  --bankcardBg: rgba(243, 243, 243, 0.5);
  --searchinputclr: #deebf0;
  --borderclr: #d7d4e000;
  --sidebarbrdr: #277a95;
  --pricevolumeBG: #b5e2e4;
  --cardsec: transparent linear-gradient(180deg, #fdfcff 0%, #ffffffba 100%) 0% 0% no-repeat;
  --shadow: 0px 0px 12px #00000012;
}

body {
  margin: 0;
  box-sizing: border-box;
  background-color: $white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: $baseFontRegular;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

#root {
  height: 100%;

}

img {
  max-width: 100%;
}

.theme-dark {

  .sidebarIcons,
  .ant-menu-item-selected,
  .ant-menu-item {
    &:hover {
      svg {
        path {
          fill: $baseblue;
        }
      }
    }
  }

  .ant-menu-item-selected,
  .ant-menu-item-selected:hover {
    .sidebarIcons {
      path {
        fill: $baseblue !important;
      }
    }
  }

  background-color: #181A20;
}


#tv_chart_container {

  margin-left: 10px;


  @media (max-width: 767px) {
    padding-left: 0;
  }
}


.lightTheme {
  background-color: #fafafa;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  height: 100vh;
}



.ant-layout-content {
  display: flex;
  flex-direction: column;
  background: var(--main-bg);
  margin: 0 !important;


  .flexAuto {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 50px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }

  @media (max-width: 676px) {
    padding: 0 15px;
  }
}



.ant-table {
  background-color: var(--homeCard) !important;
  height: 100%;
  overflow: auto;

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody>tr>td {
    @include fluid-type(320px, 1920px, 12px, 15px);
  }

  .ant-table-thead>tr>th {
    font-weight: 600;
    color: var(--smalp);
  }
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;

  tr th {
    border-bottom: 0 !important;
    white-space: nowrap;
  }

  @include fluid-type(320px, 1920px, 10px, 14px);
  border-bottom: 0 !important;
  white-space: nowrap;
}

td {
  background-color: transparent;

  border-bottom: 0 !important;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

.ant-empty {
  .ant-empty-description {
    color: var(--emptytext);
  }
}


.ant-layout-sider {
  box-shadow: 5px 0 3px -3px var(--sidebarbrdr);

  &-trigger {
    color: $darktext;
    border: 1px solid;
  }
}

.ant-menu {
  &-item {
    padding-left: 30px !important;
    margin-top: 0 !important;

    &-icon {
      display: flex !important;
      height: 100% !important;
      align-items: center !important;
      padding-left: 4px;
    }

    &-only-child {
      position: relative;
      padding-left: 80px !important;

      &::before {
        content: "";
        position: absolute;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 100%;
      }
    }
  }

  &-submenu {
    &-title {
      margin-top: 0 !important;
      padding-left: 30px !important;
    }
  }
}

.ant-menu-submenu-popup {
  li {
    padding-left: 30px !important;

    &::before {
      content: unset;
    }
  }
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
  padding: 0 calc(50% - 30px) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 3px;
  height: 20px;
}

.scrollBar:hover::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
}

table td,
th {
  white-space: nowrap;
}

.timerDesign {
  span {
    background: #fcd535;
    padding: 4px 3px;
    margin: 0px 5px;
    border-radius: 2px;
    font-weight: 500;
  }
}



.headingCommon {
  @include fluid-type(320px, 1920px, 16px, 24px);
  font-weight: bold;
  color: $darktext;
  line-height: 33px;
}

.otpFields {
  >div {
    margin-top: 10px;

    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  input {
    border: 1px solid $bordergrey;
    border-radius: 16px;
    height: 60px !important;
    width: 67px !important;
    background-color: #ffff;
    margin: 26px 10px 10px 0px !important;

    @media (max-width: 767px) {
      height: 40px !important;
      width: 45px !important;
      border-radius: 10px;
    }

    @media (max-width: 420px) {
      height: 30px !important;
      width: 35px !important;
      border-radius: 8px;
    }

    &:focus-visible {
      outline: 1px solid $baseblue;
      border: none;
    }
  }
}

.mainLayout {
  min-height: 100vh;
  height: 100%;

  @media (max-width: 767px) {
    min-height: unset;
  }

  .ant-layout-content {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-layout-header {
    background-color: #fff;
  }

  .ant-layout-sider {
    background: var(--sidebar-bg);

    max-width: 252px !important;
    min-width: 252px !important;
    width: 252px !important;
    z-index: 99;
  }

  .logo {
    cursor: pointer;
    height: 76px;
    padding: 25px 27px 0px 40px;
    top: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      img {
        width: 60px;
      }
    }
  }

  .ant-layout-header {
    height: 86px !important;
    border-bottom: 1px solid $bordergrey;
  }

  .ant-layout-sider-collapsed {
    max-width: 120px !important;
    min-width: 120px !important;
    width: 120px !important;

    @media (max-width: 767px) {
      max-width: 80px !important;
      min-width: 80px !important;
      width: 80px !important;
    }

    .ant-menu {
      padding-right: 24px;

      @media (max-width: 767px) {
        padding-right: 0;
      }
    }
  }

  .ant-layout-sider-children {
    overflow-y: scroll;
  }

  .ant-menu {
    padding: 0 24px;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    gap: 4px;

    svg {
      min-width: 24px;
      height: 24px;
    }

    &.collapsed {
      transition: 0.3s all;
      width: 105px !important;

      .ant-menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
          justify-content: center;
        }

        .ant-menu-title-content {
          display: none;
        }
      }
    }

    .ant-menu-item-selected {
      background: var(--selcted-Tab) !important;
      position: relative;
      box-shadow: 0px 0px 12px #0000001a;
      border-radius: 12px;

      .ant-menu-title-content {
        color: var(--headerText1) !important;
      }


      .ant-menu-item-icon {
        background: var(--sidebariconbg);
        border-radius: 12px;
        justify-content: center;
        padding-left: 0;

        &:hover {
          background: var(--sidebariconbg);
        }
      }
    }

    .ant-menu-item {
      font-weight: 500;
      @include fluid-type(320px, 1920px, 12px, 16px);
      padding-left: 12px !important;
      height: 50px;
      margin-bottom: 0px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;

        span {
          display: none;
        }
      }

      .ant-menu-item-icon {
        width: 32px;
        height: 32px !important;
      }

      &:hover {
        background: var(--selcted-Tab) !important;
        border-radius: 12px;
        color: $white;

        .ant-menu-title-content {
          color: var(--headerText1) !important;
        }

        .ant-menu-item-icon {
          svg {
            path {
              fill: var(--headerText1) !important;
            }
          }
        }
      }

      .ant-menu-title-content {
        color: $white;
        @include fluid-type(320px, 1920px, 12px, 16px);
        margin-left: 12px;
      }
    }

    .ant-menu-item-active {
      background-color: $white;
    }
  }

  .fa {
    content: "\f105";
    color: $borderdarkgrey;
  }
}

.ant-card-body {
  padding: 0 !important;
}

.customModalStyle {
  .ant-modal-content {
    border-radius: 20px;
    width: 100%;
    margin: auto;
    background: var(--modalBg);
    box-shadow: 0px 0px 12px #00000012;
    border-radius: 20px;
    opacity: 1;
    padding: 50px;
  }

  .ant-modal-header {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
    text-align: left;
  }

  .ant-modal-body {
    padding: 0px;
  }
}

.copydataIcon {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: #1258ee;
  color: #fff;
  box-shadow: inset 0px 0px 24px white;
  border-radius: 10px;
  pointer-events: all;
  transition: all 0.1s linear;
  opacity: 1;

  &:empty {
    opacity: 0;
    pointer-events: none;
  }
}


.btngroup {
  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .ant-btn.btn_custom {
      height: 40px !important;
      width: 100%;
      padding: 10px 40px;
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 600;

      span {
        @include fluid-type(320px, 1920px, 14px, 16px);
      }
    }

    .btnCustom.ant-btn.lytgaryBtn {
      @include fluid-type(320px, 1920px, 14px, 16px);
      height: 40px;
    }
  }
}

.icons {
  &.padding0 {
    button {
      padding: 0 !important;
    }
  }

  .btnCustom.ant-btn.lytgaryBtn {
    height: 40px;
    width: 40px;

    @media (max-width: 767px) {
      height: 30px !important;
      width: 30px !important;
    }
  }

  .ant-modal-close {
    top: 0;
    right: 0;
  }

  &.TableModalStyle {
    .ant-modal-header {
      border-bottom: none;

      .blue {
        color: $baseblue;
      }
    }

    .ant-modal-close {
      background-color: $white;
      border-radius: 50%;
      top: -44px;
      right: -19px;

      .ant-modal-close-x {
        width: 30px;
        height: 30px;
        line-height: 2;
      }
    }
  }
}



.priceTable {
  cursor: pointer;

  .ant-table-thead {
  }

  thead>tr>th {
    background-color: var(--collpasedata);

    &:first-child {
      border-top-left-radius: 10px !important;
      border-bottom-left-radius: 10px !important;
    }

    &:last-child {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }

  .ant-table {
    background-color: transparent !important;

    tr td {
      background: var(--gridCard);
    }

    tr td:first-child {
      border-radius: 7px 0 0 7px;
      padding-left: 36px;

      @media (max-width: 767px) {
        padding-left: 15px;
      }

      &:hover {
        border-radius: 7px 0 0 7px;
      }
    }

    tr td:last-child {
      border-radius: 0 7px 7px 0;
      padding-left: 36px;

      &:hover {
        border-radius: 0 7px 7px 0;
      }
    }

    tr {
      &:hover {
        td {
          background: rgb(0, 133, 176) !important;
        }
      }
    }

    .ant-table-row {
      border-radius: 7px;
      opacity: 1;

      &:hover {
        border-radius: 7px;

        td {
          background: $baseblue !important;
          color: $white;
        }

        p {
          color: $white;
        }
      }
    }
  }

  table {
    border-spacing: 0 1em !important;

  }

  .bitData {
    display: flex;
    align-items: center;
    gap: 8px;

    .ant-table-thead>tr>th {
      position: relative;
      text-align: left;
      background: #fff !important;
      color: rgba(0, 0, 0, 0.85);
    }

    p {
      margin-bottom: 2px;
      color: var(--smalp);
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 500;
    }

    .coins {
      font-weight: 600;
      color: var(--headerText);
      @include fluid-type(320px, 1920px, 12px, 16px);
    }
  }

  .downarrows {
    img {
      margin-right: 4px;
    }
  }
}
.tribe {
  &.triveTxtCenter {
    display: flex;
    align-items: center;
  }

  p {
    color: var(--smalp);
    @include fluid-type(320px, 1920px, 12px, 14px);
  }

  .topmovericon {
    font-weight: 600;
    color: var(--headerText);
    @include fluid-type(320px, 1920px, 12px, 16px);
  }
}

.pricedatamover {
  text-align: right;
  color: var(--headerText);
  @include fluid-type(320px, 1920px, 12px, 16px);

  .downarrows {
    white-space: nowrap;
  }

  p {
    color: var(--headerText);
    @include fluid-type(320px, 1920px, 12px, 16px);

    span {
      display: flex;
      justify-content: flex-end;
    }

    img {
      margin-right: 4px;
    }
  }
}

.tableBorderNone {
  .ant-table-content {
    table {
      tbody {
        tr {
          td {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

.portfolioTable {
  width: 100%;

  .ant-table {
  }

  &.cursorInitial {
    .ant-table-tbody>tr {
      cursor: initial;
    }
  }

  .bitData {
    display: flex;
    align-items: center;
    gap: 6px;

    .bitcoindata {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-table-content {
    table {
      min-width: 520px;

      @media (max-width: 767px) {
        min-width: unset;
      }
    }
  }

  .ant-table-thead>tr {
    >th {
      background: var(--collpasedata) !important;

      &:first-child {
        padding-left: 28px;

        @media (max-width: 1199px) {
          padding-left: 20px;
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      td.ant-table-cell-row-hover {
        background-color: var(--hoverWhite) !important;
      }
    }
  }

  .ant-table-tbody>tr:last-child td {
    border-bottom: none !important;
  }

  .btcvalues {
    display: flex;
    flex-direction: column;
  }

  .ant-table-tbody>tr {
    cursor: pointer;

    >td {
      border-bottom: 1px solid var(--bordercolor) !important;
      color: var(--btntext);
      padding: 11px;
      padding-right: 25px;

      &:first-child {
        padding-left: 28px;

        @media (max-width: 1199px) {
          padding-left: 20px;
        }
      }

      @media (max-width: 767px) {
        padding-right: 15px;
      }
    }
  }

  h6 {
    font-size: 14px;
  }
}

.rewardsData {
  border-bottom: 1px solid var(--bordercolor) !important;
  margin-bottom: 0 !important;
  padding: 17px 25px;

  @media (max-width: 1400px) {
    padding: 17px 10px;
  }

  @media (max-width: 991px) {
    &:last-child {
      border-bottom: none !important;
    }
  }

  .tribeData {
    @media (max-width: 480px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .pricedatamover {
    @media (max-width: 480px) {
      text-align: center;
    }
  }
}


div[class$="card"] {
 


  li {
    cursor: pointer;
  }
}


.drawerLanding {
  .ant-drawer-content-wrapper {
    max-width: 378px;
    width: 100% !important;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;

    .ant-drawer-close {
      margin: 0;
    }
  }
}

.ant-drawer-title {
  h2 {
    color: $baseblue !important;
  }
}

.ant-drawer-wrapper-body {
  a {
    display: block;

    color: var(--light-text);
    padding: 10px 0;

    &:hover {
      color: $baseblue;
    }
  }
}

.bg-white {
  background-color: #161a25 !important;

  opacity: 1;
}

.ant-drawer-header,
.ant-drawer-wrapper-body {
  background-color: var(--cardBackground) !important;

  button.ant-btn {
    background-color: $baseblue !important;
    color: $white !important;

    &:hover {
      opacity: 0.8;
    }
  }
}

.ant-drawer-header {
  background-color: transparent !important;
  border-bottom: none;

  .anticon {
    color: var(--headerText) !important;
  }
}

/*======================comman-text===============*/

h3[class^="text-black-36"] {
  color: $white;
  font-weight: 600;
  @include fluid-type(320px, 1920px, 20px, 36px);
  line-height: 50px;
  font-family: Open Sans;
}

h4[class^="text-black-20"] {
  padding: 28px 28px;
  color: $darktext;
  @include fluid-type(320px, 1920px, 14px, 20px);
  font-weight: 600;
  color: var(--btntext);

  @media (max-width: 1199px) {
    padding: 22px 20px;
  }
}

h4,
h2[class$="blue"] {
  color: $baseblue;
}

h2[class^="logo"] {
  font-weight: 800;
  @include fluid-type(320px, 1920px, 18px, 36px);
}

h2[class$="white"] {
  color: $white;
  display: flex;
  align-items: center;
}

/*text........*/
p[class^="text-grey-14"] {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 12px, 14px);

  a {
    color: $baseblue;
    text-decoration: underline;
  }
}

p[class$="twelve"] {
  @include fluid-type(320px, 1920px, 10px, 12px);
}

p[class^="grey"] {
  color: $text-80838E;
}

h6[class^="black14"] {
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: var(--headerText);

  span {
    color: var(--headerText);
  }
}

div[class$="card"] {
  box-shadow: var(--shadow);
  background: var(--cardBackground) 0% 0% no-repeat padding-box;
  border-radius: 20px;


  li {
    cursor: pointer;
  }

  .borderReward {
    border-bottom: 1px solid gray !important;
  }
}

div[class^="outer"] {
  margin: 30px 40px 30px 50px;
  box-shadow: 0px 0px 12px #00000012;
}

.select-leftLabel {
  .SelectOuter {
    display: flex;
    align-items: center;

    .label {
      width: 120px;
      color: $text-80838E;
    }

    .customSelect.ant-select {
      width: 100%;
      max-width: 154px;
      height: 40px;
      margin-bottom: 0;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 21px;
    transition: all 0.3s;
  }
}

a.ant-btn {
  padding: 0 !important;
}

/*=============================tabs-design======================*/
.ant-tabs,
.ant-tabs-top {
  .ant-tabs-nav-more {
    @media (min-width: 991px) {
      display: none !important;
    }
  }

  .anticon {
    svg {
      color: var(--whiteblack) !important;
    }
  }

  &.blueTabs {
    .ant-tabs-nav {
      &::before {
        width: 0;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
      

          @media (max-width: 991px) {
            width: calc(100% - 10px);
          }

          @media (max-width: 767px) {
            width: unset;
          }

          .ant-tabs-tab {
            color: #ffffff7a;
            padding: 22px 20px 16px 20px;
            font-weight: 400;
 
            @include fluid-type(320px, 1920px, 12px, 16px);
            justify-content: center;

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $baseblue;
              }
            }
          }

          .ant-tabs-ink-bar {
            background: none;
            bottom: 6px;
          }
        }
      }

   
    }
  }

  &.decorationNone {
    .ant-tabs-nav {
      background: var(--setingCardBg);
      box-shadow: 0px 3px 16px #00000017;
      border-radius: 20px;
      opacity: 1;
      padding: 15px 24px;

      &::before {
        display: none;
      }

      .ant-tabs-ink-bar {
        display: none;
      }

      .ant-tabs-nav-wrap {
        border-top-left-radius: unset !important;

        .ant-tabs-nav-list {

          background-color: #f1eff5;
          border-radius: 12px;

          .ant-tabs-tab {
            color: $text-80838E;
            padding: 13px 23px;
            font-weight: 400;
 
            @include fluid-type(320px, 1920px, 12px, 16px);

            &.ant-tabs-tab-active {
              background: var(--themebgbtn);
              opacity: 1;
              border-radius: 12px;

              .ant-tabs-tab-btn {
                color: $white;
              }
            }
          }

          .ant-tabs-ink-bar {
            background: transparent;
          }
        }
      }
    }

    .ant-tabs-content-holder {
      background: var(--setingCardBg);
      box-shadow: 0px 3px 16px #00000017;
      border-radius: 20px;
      opacity: 1;
      margin-top: 20px;
    }
  }

  &.transactionshistoryTab {
    .ant-tabs-nav-wrap {
    

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 18px 30px !important;
          max-width: 125px;
          @include fluid-type(320px, 1920px, 12px, 16px);

          @media (max-width: 767px) {
            padding: 15px !important;
          }

          &-active {
            border-bottom: 2px solid #014cec;
          }
        }
      }
    }
  }

  & .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          color: $text-80838E;
          font-weight: 500;
          @include fluid-type(320px, 1920px, 14px, 20px);
          padding: 16px 50px;

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $baseblue;
            }
          }
        }

        .ant-tabs-ink-bar {
          background: $baseblue;
        }
      }
    }
  }
}

.remove-margin {
  .ant-divider-horizontal {
    margin: 0;
  }
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 !important;

  &:first-child {
    margin-left: 40px;
  }
}

.ant-tabs-top>.ant-tabs-nav {
  margin: 0 !important;
}

.line-none {
  .ant-tabs-nav {
    padding: 0 45px !important;

    @media (max-width: 1366px) {
      padding: 0 20px !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 500;
      color: $white !important;
    }
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: 500;
  }

  &.ant-tabs-top>.ant-tabs-nav {
    &::before {
      border-bottom: none;
    }
  }

  .ant-tabs-ink-bar {
    background: white !important;
    bottom: 1px !important;
  }
}

.portTab {
  width: 100%;

  .ant-tabs-tab {
    @media (max-width: 485px) {
      padding: 22px 2px 16px 10px !important;
    }

    @media (max-width: 380px) {
      padding: 22px 2px 16px 5px !important;
    }
  }

  .ant-tabs-ink-bar {
    background: $white !important;
  }

  &.blueTabs {
    .ant-tabs-nav-wrap {
      padding: 0 15px;
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        @include fluid-type(320px, 1920px, 14px, 16px);
        font-weight: 500;
        color: $white !important;
      }
    }

    .ant-tabs-tab-btn {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 500;

      &:focus {
        color: var(--light-text);
      }

    }
  }

  .ant-tabs-nav-list {
    align-items: flex-end;
  }

  .ant-tabs-nav {

    height: 198px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;

    @media (max-width: 380px) {
      height: 220px;
    }
  }

  .ant-tabs-nav-wrap {
    height: 60px;
    align-self: end !important;
    border-top: 1px solid #ffffff7a;

    opacity: 1;
  }

}

input[type="radio"] {
  accent-color: $baseblue;
  width: 17px;
  height: 17px;
}

.buySellTab {
  .ant-tabs-nav-list {
    width: 100%;
    border-radius: 20px;

    :first-child {
      border-radius: 20px 0 0 0;
    }

    :nth-child(2) {
      border-radius: 0 20px 0 0;
    }
  }

  .ant-tabs-tab {
    flex: 50%;
    padding: 14px 45px !important;

    @media (max-width: 1420px) {
      padding: 14px 30px !important;
    }

    @media (max-width: 1200px) {
      padding: 14px 45px !important;
    }
  }

  .card {
    ul {
      li {
        border-bottom: 1px solid var(--bordercolor) !important;
      }

      &:last-child {
        li {
          border-bottom: none !important;
        }
      }
    }
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: 600;
  }

  .ant-tabs-nav-list {
    :last-child {
      .ant-tabs-tab-active {
        background: red !important;
      }
    }
  }

  .ant-tabs-tab-active {
    color: black !important;
    background: transparent linear-gradient(98deg, #2995a1 0%, #3fbcc5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 12px #00000012;

    .ant-tabs-tab-btn {
      color: $white !important;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

/*=======================buy-sell-conver===================*/

.sendForm {
  .inputSec {
    width: 100%;

    .ant-input {
      width: 100% !important;
      @include fluid-type(320px, 1920px, 13px, 17px);
    }
  }

  .amountField {
    border-radius: 25px;
  }

  .ant-input-suffix {
    color: #1ea0ab;
    font-weight: 600;
  }

  .ant-input-affix-wrapper.amountField {
    background: var(--homeCard) !important;
    border: 1px solid #d7d4e0 !important;

    .ant-input-suffix {
      img {
        margin-right: 5px;
      }
    }
  }
}

.inputSec {
  sup {
    font-size: 24px;
    margin: 28px 0;
    color: $baseblue;
    top: -6px;

    @media (max-width: 1199px) {
      top: -3px;
    }

    @media (max-width: 585px) {
      font-size: 19px;
      top: -2px;
    }
  }

  .ant-input {
    border: none;
    width: 135px;
    @include fluid-type(320px, 1920px, 20px, 35px);
    font-weight: 600;
    color: $baseblue;
    height: 30px;
    background-color: transparent;
    padding: 0px 5px !important;

    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
}

.btcvalues {
  >div {
    padding: 20px 24px;
    display: flex;
    gap: 16px;

    @media (max-width: 1780px) {
      gap: 7px;
      padding: 16px;
    }

    @media (max-width: 1660px) {
      justify-content: center;
      gap: 15px;
    }

    button {
      height: 40px !important;
      max-width: 124px;
      width: 100%;

      @media (max-width: 1600px) {
        max-width: 100px;
      }

      @media (max-width: 575px) {
        height: 35px !important;
        max-width: 100px;
      }

      span {
        @include fluid-type(320px, 1920px, 13px, 16px);
        font-family: $baseFontRegular;
        font-weight: 500;

        &:first-child {
          margin-right: 7px;
        }
      }
    }
  }
}

.wallettabs {
  padding-top: 24px;

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-bottom>.ant-tabs-nav::before,
  .ant-tabs-bottom>div>.ant-tabs-nav::before,
  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before {
    border-bottom: 0px;
  }

  .ant-tabs {
    .ant-tabs-tab {
      padding: 0px 20px 0 0 !important;
      display: block;

      .ant-tabs-tab-btn {
        padding-bottom: 6px;
        text-align: center;
        color: var(--smalp);
        @include fluid-type(320px, 1920px, 12px, 14px);
        font-family: $baseFontRegular;
      }
    }
  }

  .blueTabs {
    .ant-dropdown-trigger {
      color: var(--whiteblack);
    }
  }
}

.grey-bg {
  background-color: $bg-light-gray-F3F3F3;
  color: $darktext;
  text-align: center;
  padding: 14px 33px;
  border-radius: 25px;
  margin: 0 auto 32px auto;
  display: block;
  max-width: 150px;

  &:hover {
    color: $baseblue;
  }
}

.convertIcon {
  li {
    &:last-child {
      &:after {
        content: url("./Components/Assets/Images/Exchange.svg");
        width: 32px;
        height: 32px;
        position: absolute;
        left: 13px;
        top: -15px;
        background-color: $bg-light-gray-F3F3F3;
        text-align: center;
        padding: 8px;
        border-radius: 50%;
      }
    }
  }
}

.dFlexGap20 {
  display: flex;
  gap: 30px;
}

.convertIconSpan {
  content: url("./Components/Assets/Images/Exchange.svg");
  width: 32px !important;
  height: 32px;
  position: absolute;
  left: 13px;
  top: -17px;
  background-color: $bg-light-gray-F3F3F3;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
}

.transferIcon {
  transform: rotate(90deg);
}

.exchagetitle {
  padding: 0 21px;
  background-color: var(--tradebg);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1780px) {
    padding: 16px;
  }

  h4 {
    margin: 0px;
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 600;
    color: #1ea0ab;
  }
}

.orange {
  color: $red-CF304A;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.green {
  color: $green;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.grey {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.btcvalues {
  button {
    height: 40px !important;

    @media (max-width: 575px) {
      height: 35px !important;
    }
  }
}

.grey {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.green {
  color: $green-098551;
}

.tradeHistory {
  margin-bottom: 22px;

  .marketTradetabs {
    padding: 0;

    .ant-tabs-tab {
      padding: 14px !important;
      font-size: 14px !important;
    }
  }

  .ant-pagination {
    display: none;
  }

  .ant-table-content {
    overflow: auto;
    height: 340px;
    background-color: var(--tradebg);

    @media (max-width: 1366px) {
      height: 335px;
    }

    @media (max-width: 767px) {
      height: calc(100vh - 270px);
    }
  }

  .ant-table-wrapper {
    .ant-table {
      .ant-table-thead {
        margin-bottom: 10px;
        position: sticky;
        top: 0;

        .ant-table-cell {
          font-weight: 500;
          background-color: var(--tradebg);
          padding: 10px 22px !important;
          border-bottom: 1px solid $bordergrey !important;
          @include fluid-type(320px, 1920px, 10px, 12px);

          &:last-child {
            text-align: right;

            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-left: 5px !important;
            }
          }

          &:nth-child(2) {
            text-align: center !important;

            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }

          &:nth-child(1) {
            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-right: 5px !important;
            }
          }

          @media (max-width: 1780px) {
            padding: 10px 16px !important;
          }
        }
      }

      .ant-table-tbody {
        max-height: 188px;

        .ant-table-row {
          &:first-child td {
            padding-top: 14px !important;
          }

          td {
            &.ant-table-cell-row-hover {
              background-color: var(--hoverWhite);
            }

            @include fluid-type(320px, 1920px, 12px, 12px);
            padding: 0px 22px;

            @media (max-width: 1780px) {
              padding: 0px 16px !important;
            }

            &:nth-child(1) {

              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-right: 5px !important;
              }
            }

            &:nth-child(2) {
              text-align: center !important;
              color: var(--smalp);

              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-left: 0 !important;
                padding-right: 0 !important;
              }
            }

            &:nth-child(3) {
              color: var(--smalp);
              text-align: right;

              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-left: 5px !important;
              }
            }
          }
        }
      }

      .ant-table-pagination {
        display: none !important;
      }
    }
  }
}

.fundingtable {
  border-radius: 20px !important;
  overflow: hidden;
  margin-top: 30px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    background-color: var(--collpasedata);
    color: var(--whiteblack);
    border-radius: 10px;
    align-items: center;
  }
}

.assetTable {
  padding: 30px 38px 30px 50px;
  width: 100%;
  max-width: auto;
  overflow: hidden;

  @media (max-width: 1366px) {
    padding: 20px;
  }

  h4 {
    color: var(--btntext);
    @include fluid-type(320px, 1920px, 14px, 20px);
    font-weight: 600;
    padding-bottom: 14px;
  }

  p {
    color: var(--smalp);
  }

  .fundingtable {
    border-radius: 20px !important;
    overflow: hidden;
    margin-top: 30px;
  }

  .ant-table-thead>tr>th {
    background-color: $portfolioHead;
    font-weight: 600;

    &:first-child {
      padding-left: 28px;

      @media (max-width: 991px) {
        padding-left: 20px;
      }
    }
  }

  .ant-table-tbody>tr>td {
    border-bottom: 1px solid $tableborders !important;
    color: var(--smalp);
    @include fluid-type(320px, 1920px, 12px, 14px);

    &.ant-table-cell-row-hover {
      background-color: var(--hoverWhite);
    }

    &:first-child {
      padding-left: 28px;

      @media (max-width: 991px) {
        padding-left: 20px;
      }
    }
  }

  .ant-btn-link {
    color: $baseblue;
  }
}


.drawerStyle {
  .btngroup>div {
    flex-direction: column;

    @media (max-width: 991px) {
      align-items: flex-start;
    }
  }

  .btngroup {
    max-width: 180px;
    margin: auto;

    @media (max-width: 991px) {
      text-align: left;
      max-width: 100%;
    }

    @media (max-width: 991px) {
      button.ant-btn {
        background: transparent !important;
        color: var(--headerText) !important;
        padding: 0 !important;
      }

      .ant-btn.btn_custom.themebtn {
        background: transparent !important;
        color: var(--headerText) !important;
        padding: 0 !important;
      }

      .ant-btn.btn_custom {
        justify-content: left;
        padding: 0 !important;
        height: 20px !important;
      }
    }
  }
}

.mb24 {
  margin-bottom: 24px;
}

/*=============40btn===========*/
button[class^="btn40"] {
  height: 40px;
  border-radius: 25px;
  width: 120px;
  border-color: transparent;
}

.tb-grey-btn {
  color: #181A20;

  &:hover {
    background-color: $baseblue;
    color: $white;
  }
}


.depositModals {
  display: flex;
  justify-content: space-between;
  padding: 4px 20px;
  width: 100%;
  margin-bottom: 36px;

  &:hover {
    background-color: $bg-light-gray-F3F3F3;
    border-radius: 14px;
  }

  .bitassetData {
    display: flex;
    gap: 6px;

    p {
      margin-bottom: 0;
      @include fluid-type(320px, 1920px, 12px, 16px);
    }

    :first-child {
      color: $darktext;
    }

    :nth-child(2) {
      @include fluid-type(320px, 1920px, 12px, 14px);
      color: $text-80838E;
    }
  }
}

.setting {
  text-align: center;

  .ant-dropdown-menu {
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 6px 10px #00000029;
    border-radius: 10px;
    max-width: 256px;
    background: var(--modalBg);

    .ant-dropdown-menu-item {
      padding-left: 28px;
      padding-right: 28px;

      color: var(--headerText);
      font-size: 16px;

      &:nth-child(1),
      &:nth-child(2) {
        &:hover {
          background-color: transparent;
        }
      }

      &:nth-child(1) {
        .ant-dropdown-menu-title-content {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          font-size: 18px;

          .noNotification {
            font-weight: 500 !important;
            font-size: 16px;
          }

          button {
            background-color: transparent;
            border: 1px solid $bordergrey;
            border-radius: 18px;
            padding: 9px 18px;
            color: $text-80838E;
            margin-bottom: 16px;
          }
        }
      }

      &.ant-dropdown-menu-item-active {
        background-color: transparent;
        border-radius: 10px !important;
      }
    }
  }
}

.submitted,
.accepted,
.processing,
.pending {
  color: #ffb011;
}

.collected,
.completed,
.deposited,
.succeed,
.signup,
.Deposit,
.trade,
.confirming,
.sucess,
.receive {
  color: #098551;
}

.cancelled,
.rejected,
.failed,
.danger {
  color: #cf0038;
}

.cursorInitial {
  cursor: initial;
}

.height_flicker {
  td {
    height: 70px !important;
  }
}

.spotTable {

  td,
  th {
    &:last-child {
      text-align: right;
    }
  }
}

.coinViewTable {
  .ant-table-content {
    height: 400px;
    overflow: auto;
  }
}

.storImgs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.headerModals {
  background: (--setingCardBg);

  .ant-modal-content {
    background: var(--modalBg);

    border-radius: 20px;
    overflow-y: hidden;
  }

  .ant-modal-body {
    padding: 0;
  }
}


.exchangeFooter {
  height: 45px;
  border: 1px solid $bordergrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0px;

  p {
    margin: 0px;
    text-align: center;
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: $grey-707070;
  }
}

.sendRecived {
  background: var(--modalBg);
  box-shadow: 0px 0px 12px #00000012;
  border-radius: 20px;

  .ant-tabs-content {
    position: inherit;
  }

  .ant-tabs-nav-list {
    width: 100% !important;
  }

  .ant-tabs-tab {
    max-width: 49.5%;
    width: 100%;
  }

  .ant-tabs-tab-active {
    border-bottom: 2px solid $baseblue;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    display: block;
  }

  .ant-tabs-tab-btn {
    text-align: center;
    width: 100%;
    color: $text-80838E;
    font-weight: 600;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: $baseblue !important;
    }
  }
}

.sendreceivetrans {
  box-shadow: none;
}

.tabsDrawer {
  margin-top: 10px;

  .ant-tabs-tab {
    padding: 0 !important;
    margin-left: 0px !important;
    background-color: transparent;

    p {
      padding: 0 6px;
      display: flex;
      font-size: 16px;

      span {
        margin-right: 3px;

        svg {
          width: 17px;
          height: 16px;
        }
      }
    }

    .ant-tabs-tab-btn {
      font-size: 12px;
    }
  }

  .ant-tabs-content-holder {
    max-height: 300px;
    overflow: auto;
  }

  .ant-tabs-nav-list {
    gap: 6px !important;
  }

  .ant-tabs-nav {
    padding-bottom: 10px;

    &::before {
      border: 0px !important;
    }
  }

  .ant-tabs-tab-active {
    border-bottom: 0px;

    .ant-tabs-tab-btn {
      color: var(--headerText1) !important;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.marketcomp {
  margin: 14px 0px;
  border-radius: 37px;
  padding: 10px 20px;
  border-radius: 37px;
  background-color: var(--tradebg);

  @media (max-width: 575px) {
    padding: 8px 10px;
    border-radius: 20px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    gap: 5px;

    li {
      white-space: nowrap;
      margin-left: 5px;
      margin-right: 5px;

      @media (max-width: 575px) {
        margin-left: 0px;
        margin-right: 0px;
      }

      &:first-child {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      canvas {
        width: 100px !important;
        display: flex;

        @media (max-width: 575px) {
          width: 70px !important;
        }
      }

      h3 {
        @include fluid-type(320px, 1920px, 10px, 14px);
        font-weight: 600;
        color: var(--btntext);
      }

      p {
        @include fluid-type(320px, 1920px, 10px, 14px);
        color: var(--smalp);
      }
    }
  }
}


.stateSelect {
  .SelectOuter {
    display: flex;
    align-items: center;
    height: 40px;

    label {
      margin-right: 10px;
      padding-bottom: 0;
    }

    .select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 19px;
    }

    .customSelect.ant-select {
      height: 40px;
      width: 144px;
      margin-bottom: 0;
      background-color: $bg-light-gray-F3F3F3;
      border: none;
    }

    .ant-select-selector {
      height: 40px;

      .ant-select-selection-placeholder {
        display: inline-block;
        width: 144px;
      }
    }
  }
}



.search {
  position: relative;
  height: 40px;

  .ant-input.input_custum {
    height: 40px;
    padding-left: 50px;
    width: 144px;
    background-color: $bg-light-gray-F3F3F3;
    border: none;
  }

  &::after {
    content: url("./Components/Assets/Images/search.svg");
    position: absolute;
    top: 21px;
    left: 18px;
  }
}



.verifyBtn {
  background-color: $baseblue;
  color: $white;
  border: none;
  border-radius: 8px;
  padding: 9px 20px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    display: none;
  }

  &.active {
    background-color: $blueEBF9FB;
    color: $baseblue;
    border: 1px solid #1ea0ab52;

    img {
      display: inline-block;
      margin-right: 10px;
    }
  }
}


.loginTab {
  .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 16px, 18px);
  }

  .ant-tabs-tab {
    padding: 10px 40px !important;

    @media (max-width: 767px) {
      padding: 10px 30px !important;
    }
  }

  .ant-tabs-nav {
    margin: 25px 0px !important;
  }

  .ant-tabs-top {
    margin-bottom: 10px;
  }
}

.input_righttext {
  margin-bottom: 42px;

  .input_imgcontent {
    p {
      color: $baseblue;
      font-weight: 600;
    }
  }
}

.country-list {
  width: 100%;
  background: $white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.react-tel-input .country-list .search {
  background-color: $white;
  margin-bottom: 10px;

  input {
    width: 100%;
    border: 1px solid $borderdarkgrey;
    padding-left: 30px;
    border-radius: 20px;
    height: 40px;
  }
}

.react-tel-input {
  background-color: $white !important;
  max-width: 511px;
  width: 100%;

  .ant-input {
    height: 40px !important;
    background-color: transparent;
    border-radius: 8px !important;
    margin-left: 130px;
    max-width: 71%;
    width: 100%;

    @media (max-width: 575px) {
      max-width: 80%;
      margin-left: 90px;

    }

    @media (max-width: 480px) {
      margin-left: 90px;
      max-width: 70%;
    }

    @media (max-width: 385px) {
      margin-left: 90px;
      max-width: 65%;
    }
  }

  .selected-flag {
    border: 1px solid $bordergrey !important;
    border-radius: 8px !important;
    width: 112px !important;

    &:hover {
      border-color: $baseblue !important;
    }

    @media (max-width: 575px) {
      width: 80px !important;
    }

    .flag {
      left: 21px;
    }
  }

  .no-entries-message {
    text-align: center;
  }

  .form-control {
    padding-left: 15px !important;
    border: 1px solid $bordergrey;
    background-color: $white;

    &:hover {
      border-color: $baseblue;
    }
  }
}

.flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;

  width: 100%;
  border-radius: 8px;
  cursor: pointer;

  background: transparent;



  .country-list {
    position: relative !important;
    width: 100% !important;

  }

  &::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 21px;
    background: url("./Components/Assets/Images/dropicon.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    top: 57%;
    left: 89px !important;
    transform: translateY(-50%);

    @media (max-width: 575px) {
      left: 60px !important;
    }
  }
}

.react-tel-input .flag-dropdown.open {
   border: 1px solid $bordergrey !important;

  &::after {
    transform: rotate(180deg);
    right: 17px;
    top: 60%;
    transform: translateY(-50%);
  }
}

input[type="text"] {
  padding-left: 24px;
}

.ant-picker-input {
  padding-left: 14px;
}


.buttonTabs {
  .ant-tabs-tab {
    font-weight: 400;
    padding: 9px 28px !important;
  }

  span {
    display: flex;
    gap: 8px;
    align-items: center;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .ant-tabs-tab {
    background-color: $bg-light-gray-F3F3F3;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-tabs-tab-active {
    background-color: $baseblue;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-tabs-nav .ant-tabs-nav-list {
    gap: 10px;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    color: $darktext !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    filter: brightness(0) invert(1);
  }
}


.loginTab {
  .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 14px, 18px);
  }

  .ant-tabs-tab {
    padding: 10px 40px !important;
  }

  .ant-tabs-nav {
    margin: 15px 0px !important;
  }

  .ant-tabs-top {
    margin-bottom: 10px;
  }
}

.input_righttext {
  .input_imgcontent {
    p {
      color: $baseblue;
      font-weight: 600;
    }
  }
}

.marketTitle {
  @include fluid-type(320px, 1920px, 12px, 12px);
  color: var(--smalp);
  padding-top: 10px;
}

.errorCls {
  color: $red !important;
  margin-bottom: 5px;
}

.otp_clicked {
  color: $base-hover !important;
}


.captchaStyle {
  margin: 15px 0px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.positionedContent {
  position: absolute;
  inset: 0;
  z-index: 9;
  background: var(--cardBackground);
  border-radius: 20px;
  overflow-y: auto;
  background: transparent linear-gradient(180deg, #ffffff 0%, #e6faff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #00000012;
}



.loader-container {


  .loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 10000;

    .lottieAntimationdata {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      z-index: 999;
      position: relative;

      svg {
        max-width: 400px;
      }
    }
  }
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: initial !important;
}

.ant-modal-close-x {
  .anticon svg {
    color: var(--headerText) !important;
  }
}

.titleModal {
  text-align: center;
  @include fluid-type(320px, 1920px, 14px, 16px);
  padding-top: 15px;
  font-weight: 600;
  color: var(--headerText);
}

.text-center {
  text-align: center;
}

.curserPointer {
  cursor: pointer;
}

.coin_icon {
  width: 44px;
  height: 44px;
  border-radius: 23px;
  aspect-ratio: 1;
  box-sizing: content-box !important;

  @media (max-width: 991px) {
    width: 33px;
    height: 33px;
  }
}

.activeBtn {
  background-color: #1ea0ab !important;
  color: #fff !important;
  border-radius: 5px !important;
}

.border_round {
  .ant-modal-content {
    border-radius: 20px !important;
  }
}

.height50 {
  .customSelect.ant-select {
    height: 40px;
  }

  .mb12 {
    margin-bottom: 12px;
  }
}


.ant-select-dropdown {
  .ant-pagination-options {
    background-color: grey;
  }

  .ant-select-item-option-active,
  .ant-select-item-option-selected {
    background-color: var(--hoverWhite) !important;
    color: var(--light-text) !important;
  }

  .ant-select-item-option {
    &:hover {
      background-color: var(--hoverWhite) !important;
      color: var(--light-text) !important;

      .demo-option-label-item {
        color: var(--light-text) !important;
      }
    }
  }
}

.pagination {
  padding: 15px;
  text-align: center;

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: transparent !important;

    .ant-select-item-option-content {
      &:hover {
        color: var(--light-text) !important;
      }
    }
  }


  .ant-select-item-option-active {
    color: var(--light-text) !important;
  }

  .ant-select-item-option-content {
    &:hover {
      color: #181A20 !important;
    }
  }

  .ant-pagination-options {
    .ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
      .ant-select-selector {
        background: var(--homeCard) !important;
        border: 1px solid var(--bordercolor) !important;

        .ant-select-selection-item {
          color: var(--light-text);
        }
      }
    }
  }

  .rg-pagination {
    .ant-pagination-item-ellipsis {
      color: var(--light-text);
    }



    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-prev .ant-pagination-item-link {
      border: none;
      color: black !important;
      background-color: var(--homeCard);

      .anticon {
        svg {
          color: var(--light-text) !important;
        }
      }
    }

    .ant-pagination-item {
      background-color: var(--homeCard);
      border-radius: 50%;

      a {
        color: var(--light-text) !important;
      }
    }

    .ant-pagination-item-active {
      background-color: $baseblue;
      border-radius: 50%;

      a {

        color: white;
      }

      &:hover {
        color: black;
      }
    }
  }

  .ant-pagination-item-ellipsis {
    color: var(--light-text) !important;
  }
}

.ant-pagination-item {
  border: none !important;
  background-color: transparent;

  &:hover {
    a {
      color: $white;
    }
  }
}

.input_textimg.customInputWithdarw .input_amount {
  background-color: var(--cardBackground);
  padding-right: 50px;
  border: 0;
  border-radius: 25px;
  margin-bottom: 8px;
  font-size: 12px;

  @media (max-width: 380px) {
    font-size: 10px;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
  }

  @media (max-width: 420px) {
    padding-left: 8px !important;
    padding-right: 22px;
  }

  &::placeholder {
    color: $text-80838E;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
  }
}

.otpmodal {
  .ant-modal-body {
    padding: 30px;
  }
}

.empty_data {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px !important;
  color: #80838e;
  @include fluid-type(320px, 1920px, 14px, 16px);
  font-weight: 500;
}

.dltModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: var(--headerText1);
    @include fluid-type(320px, 1920px, 14px, 20px);
  }

  p {
    color: $borderdarkgrey;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .dltBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;

    button {
      border-radius: 30px;
      opacity: 1;
      background-color: #f3f3f3;
      border: 0;
      color: $darktext;
      padding: 0px 40px;
      height: 40px;
      @include fluid-type(320px, 1920px, 14px, 16px);
    }

    .confrBtn {
      color: #cf0038;
    }
  }
}

button {
  cursor: pointer;
}

input.ant-input.amountField {
  min-width: 150px;
  max-width: 280px;
  padding: 10px !important;
}



.listingSteps {
  &__itemsDetail {
    .ant-steps-item {
      height: 82px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
      background-color: $baseblue !important;
      border: 0;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
      color: $white;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: $baseblue;
      border: 0;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
      background-color: $baseblue !important;
    }

    .ant-steps-item-tail::after {
      width: 2px !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
      color: $white;
    }

    .ant-steps-item-tail {
      padding: 33px 0 1px !important;
    }

    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      color: $borderdarkgrey;
      margin-top: 3px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      margin-top: 3px;
      color: $baseblue;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      @include fluid-type(320px, 1920px, 14px, 15px);
      padding: 0;
      margin-top: 5px;
    }
  }
}

.ant-progress-text {
  color: var(--btntext) !important;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: $baseblue !important;
}



.uploadItem {
  .ant-upload-select {
    box-shadow: 0px 0px 12px #00000012;
    border-radius: 20px;
    background-color: $white;
    width: 100%;
    padding: 34px 24px;
  }

  .ant-btn {
    border: 0;
  }
}

.cursorPointer {
  cursor: pointer;
}

.btcList {
  text-align: right;
  padding-bottom: 5px;
  border-bottom: 1px solid $bordergrey;
}

.customMarketDropdown {
  .ant-dropdown-menu {
    box-shadow: var(--shadowWhite) !important;
  }

  @media (max-width: 480px) {
    position: relative !important;
    overflow: auto !important;
  }

  .ant-dropdown-menu {
    padding: 0 !important;
    border-radius: 10px;

    &-item {
      padding: 0 !important;
      border-radius: 10px;
    }
  }
}

.open_AllOrderTab {
  .viewall {
    color: $baseblue;
    font-weight: 600;
  }

  .ant-table-cell {
    color: var(--smalp);
  }

  .ant-table-cell-row-hover {
    background: var(--hoverWhite) !important;
  }

  .ant-tabs-nav {
    background-color: var(--tradebg);
  }

  .ant-tabs-tab {
    padding: 12px 29px !important;

    @media (max-width: 480px) {
      padding: 12px !important;
    }
  }

  .ant-tabs-tab-btn {
    p {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: var(--smalp);
    }
  }
}

.passPadding {
  input {
    padding-right: 110px;
  }
}

.logo-blue {
  display: inline-block;
}



.walletdropexchange {
  .ant-dropdown {
    .ant-dropdown-menu {
      box-shadow: none !important;
      background-color: transparent;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  .ant-dropdown-menu-item:hover,
  :active,
  :focus,
  :focus-visible,
  :focus-within {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.noRecordFound {
  text-align: center;
  width: 100%;

  p {
    color: rgba(165, 163, 163, 0.85) !important;
  }
}

.nointernet {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 200px;
    margin-bottom: 50px;
  }

  .textDiv {
    text-align: center;

    h4 {
      margin: 0px;
    }
  }
}

.mt10 {
  margin-top: 10px;
}

.stackMinFont {
  font-size: 12px !important;
  margin-top: 5px;
}



button.ant-btn {
  .ant-btn-loading-icon {
    display: none;
    position: absolute;
    z-index: 99;
    background: transparent;
    left: 0;
    right: 0;
    opacity: 0.8;
    top: 0px;
    bottom: 0;

    span {
      height: 100%;

      svg {
        display: flex;
        align-content: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.assetInner {
  .dataAssets {
    width: 83px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-bottom: -5px;
    @include fluid-type(320px, 1920px, 14px, 16px);
    margin-right: 6px;
    font-weight: 600;
    color: var(--headerText) !important;
  }

  .dataassetSpan {
    color: $text-80838E !important;
    font-weight: 500;
  }
}

.btnsmall1 {
  color: white;
  width: fit-content;
  height: 30px;
  padding: 4px;
  min-width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnsmall2 {
  margin-left: 4px;
}

.cursorAuto {
  cursor: auto;
}

.ant-table-thead {
  cursor: auto;

}

.mrt30 {
  margin-top: 30px;
}

.customMarketDropdown.ant-dropdown {
  width: 439px !important;
}

@media (max-width: 767px) {
  .customMarketDropdown.ant-dropdown {
    width: 100% !important;
    z-index: 99 !important;
  }
}


@media (max-width: 767px) {
  .responsiveLayout {
    .logo {
      display: none;
    }

    .ant-layout {
      flex-direction: column !important;
      height: calc(100% - 60px);

      &-content {
        width: 100% !important;
    
      }

      &-sider {
        flex: 1 !important;
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        background: var(--sidebar-bg);
        padding-top: 5px;
        padding-bottom: 5px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9;
        border-top: 1px solid var(--bordercolor) !important;

        .ant-menu {
          flex-direction: row !important;
          justify-content: space-between !important;

          &-item {
            border-radius: 30px 30px 0 0 !important;
            border-bottom: 0 !important;
            border-left: 0 !important;
            width: 50px;

            &-active,
            &-selected,
            &:hover {
              border-left: 0 !important;
              background-color: #fff !important;
            }
          }

          &-title-content {
            display: none !important;
          }
        }
      }
    }
  }
}

.ant-table {
  tbody tr.ant-table-placeholder {
    td.ant-table-cell {
      display: revert !important;
      background-color: var(--tradebg);
    }
  }
}



.filer {
  img {
    height: 20px;
    cursor: pointer;
  }
}

.transactionmargin {
  margin-top: 111px !important;
}

.filter_tabs {
  position: absolute;
  top: -74px;
  right: 0px;
  display: flex;
  gap: 20px;
  align-items: center;
  z-index: 9;

  .exportBtn {
    background: var(--cardBackground);
    padding: 11px 16px;
    border-radius: 8px;

    &:hover {
      color: $baseblue;
    }
  }

  img {
    margin-right: 10px;
  }

 
  p {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: var(--whiteblack);

    &:hover,
    &.active {
      color: #014cec;
    }
  }

  svg {
    height: 22px;
    width: 22px;
    cursor: pointer;

    &:hover,
    &.active {
      fill: #014cec;
    }
  }
}

.filtrspacing {
  padding: 20px;
}

.centerbutns {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  gap: 30px;

  @media (max-width:991px) {
    justify-content: center;
    margin-top: 20px;
  }
}

.filtrspacing {
  background: transparent var(--cardBackground) no-repeat padding-box;
}

.labelto {
  color: #80838e;
}

.wd20 {
  width: 26% !important;
}

.wd60 {
  width: 48% !important;
}

.swapPage {
  .ant-tabs-content {
    position: inherit;
  }
}

.anticon.anticon-delete {
  color: $white;
  font-size: 24px;
  font-weight: 600;
  background: $red-CF304A;
  padding: 10px;
  border-radius: 100%;
  transition: 0.3s all;

  &:hover {
    color: $red-CF304A;
    background: $white;
  }
}

.responsive {
  @media (max-width: 767px) {
    .responsiveLayout .ant-layout {
      height: 100%;
    }
  }
}

.overflowText {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.convertHisTable {
  .ant-table {
 
    max-height: calc(100vh - 425px);
  }

  .ant-table-header {
    overflow: visible !important;
  }

  .ant-table-body {
    overflow: visible !important;

    table {
      table-layout: auto !important;
    }
  }

  .ant-table-container {

    &::after,
    &::before {
      content: none !important;
    }
  }
}

.height450Table {
  .ant-table {

    max-height: calc(100vh - 425px);
  }
}

.pdFromLeft input {
  padding-left: 30px !important;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderCls {
  color: white;
  width: fit-content;
  min-width: 40px;
  height: 30px;
  padding: 4px;
  border-radius: 10px;
}

.maintop_swap {

  border-radius: 8px;

  position: relative;

  .swapicon {
    text-align: right;
    display: block;
    margin: 0 0 auto auto;
    cursor: pointer;
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 55px;
    height: 27px;
    width: 27px;
  }

  .swapcurrent {
    height: 23px;
    width: 23px;
  }

  .rotate_iconTransfer {
    transform: rotate(30deg);
  }

  .transfer_swap {
    display: flex;
    border: 1px solid var(--bordercolor);
    border-radius: 8px;
    gap: 50px;
    margin: 10px 0px;
    align-items: center;

    &:last-child {
      margin-top: 39px;
    }

    .settheme_span {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: $text-80838E !important;
    }

    >div {
      display: flex;
      gap: 15px;
      max-width: 120px;
      width: 100%;
      padding: 10px;

      span {
        @include fluid-type(320px, 1920px, 14px, 16px);

        color: #80838e !important;
      }

      p {
        margin: 0px;
        color: #80838e;
      }
    }

    h6 {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 600;
      color: var(--headerText);
    }
  }
}

.border_radisremove {
  .card {
    border-radius: 8px;

    span {
      color: var(--whiteblack);
    }
  }
}

.sendTradeBtn {
  span.leftIcon {
    svg {
      path {
        stroke: #014cec;
      }
    }
  }
}

:where(.css-1m62vyb).ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25);
  text-transform: capitalize;
}

.coinlistupload {
  display: inline-block;
  word-break: break-all;
  margin-right: 4px;

  @media (max-width: 480px) {
    margin-right: 12px;
  }
}

.text {
  word-break: break-word;
}

.submitapplication {
  margin-right: 30px;

  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.breackSpace {
  white-space: break-spaces;
}

.settingtabs {
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    border-radius: 10px;
  }
}

.scroll-enabled {
  @media screen and (min-width: 728px) {
    overflow-x: scroll;
  }
}


.scroll-disabled {
  @media screen and (max-width: 727px) {
    overflow-x: hidden;
  }
}

.centerCaptcha {
  text-align: center;
  margin: 15px;

  @media (max-width: 767px) {
    margin: 15px 0px;
  }

  iframe {
    @media (max-width: 445px) {
      width: 265px !important;
    }

    @media (max-width: 365px) {
      width: 250px !important;
    }
  }
}

.marginTB {
  margin: 24px 0px;
}

.text_size {


  .label {
    font-size: 14px;
  }
}

.export_modal {
  .ant-modal-content {
    padding: 25px;
  }

  .centerbutns {
    margin-top: 20px;
    margin-bottom: 10px;

    button {
      width: 50%;
    }
  }

  .titleModal {
    margin-bottom: 30px !important;
  }
}

.dollarValue {
  color: var(--smalp) !important;
  margin-left: 8px;
  font-size: 12px;
}

.ant-dropdown {
  z-index: 1 !important;
}

.submissionModal {
  h2 {
    @include fluid-type(320px, 1920px, 16px, 20px);
    color: #000000;
    text-align: center;
    font-weight: 600;
  }

  p {
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: #80838e;
    text-align: center;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .submisionBtn {
    width: 100%;
    max-width: 185px;
  }
}

.comingSoon {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteblack);
  @include fluid-type(320px, 1920px, 16px, 24px);
}

.stakingModal {
  .kycModal {
    padding: 0px !important;
  }
}

.buySellModal {
  .ant-modal-close {
    top: 0px !important;
    right: 0px !important;
  }
}

.createaccountsec {
  min-height: 100vh;

  background-size: 178px 169px, 178px 169px, cover;
  background-position: right bottom, left top;
  background-repeat: no-repeat;
}

#why-us {

  background-color: #181A20;
  padding: 110px 0;


  @media (max-width: 991px) {
    padding: 30px 0;
  }
}

.mainLayout .ant-menu {
  .ant-menu-item {
    border-radius: 12px !important;
  }

  .ant-menu-item-selected:hover {
    .ant-menu-item-icon svg path {
      fill: $baseblue !important;
    }
  }

  .ant-menu-item-active {
    background-color: $white !important;
  }
}

.ant-carousel .slick-dots li {
  display: none !important;
}

.cryptotable {
  margin: auto;
  border-radius: 7px;
  overflow-x: auto;
  border: none;

  .ant-table-content {
    table {
      background-color: $white;

      tr {
        td {
          cursor: pointer !important;
        }
      }
    }
  }

  thead.ant-table-thead {
    border-radius: 7px;
    background: $bg-F3F2F6;
    height: 66px;
    margin-bottom: 80px !important;

    tr {
      th.ant-table-cell {
        color: $darktext;
        @include fluid-type(320px, 1920px, 12px, 14px);

        &::before {
          display: none;
        }
      }
    }
  }

  .ant-table-thead>tr>th {
    background-color: transparent;
    white-space: nowrap;

    &:first-child {
      padding-left: 3.3rem;
    }

    &::before {
      width: 0rem;
    }
  }

  .ant-table {
    background: transparent !important;
    color: $text-80838E;
    border-radius: 7px;

    table {
      padding: 5px;
      border-collapse: separate;
      border-spacing: 0 15px;
      background: transparent !important;

      thead {
        background: transparent !important;

        tr {
          background: transparent;

          th {
            background: $bg-F3F2F6;
          }

          th:first-child {
            border-top-left-radius: 7px !important;
            border-bottom-left-radius: 7px !important;
          }

          th:last-child {
            border-top-right-radius: 7px !important;
            border-bottom-right-radius: 7px !important;
          }
        }
      }

      tbody {
        background: transparent !important;

        tr {
          border-radius: 7px;
          background: #278c9d !important;

          td {
            background: $white;
          }

          td:first-child {
            border-radius: 7px 0 0 7px !important;
          }

          td:last-child {
            border-radius: 0 7px 7px 0 !important;
          }
        }
      }
    }

    tbody.ant-table-tbody {
      tr.ant-table-row {
        background-color: $white;
        box-shadow: 0px 0px 12px #00000017;
        border-radius: 7px;

        &.ant-table-row-level-0 {
          &:nth-child(even) {
            background: $white;
          }

          .ant-table-cell {
            img {
              border-radius: 24px;
            }
          }

          td.ant-table-cell {
            font-size: 1.6rem;
            @include fluid-type(320px, 1920px, 14px, 16px);
            font-weight: 400;
            color: $darktext;

            img {
              border-radius: 24px !important;

              @media (max-width:991px) {
                width: 25px;
                height: 25px;
                max-width: none;
              }
            }

            &.ant-table-cell-row-hover {
              background-color: var(--hoverWhite);
              color: white;
            }

            &:first-child {
              padding-left: 3.3rem;
              font-weight: 600;
            }
          }
        }
      }
    }

    .graytext {
      color: $text-80838E;
      font-size: 1.6rem;
    }

    .ant-table-tbody>tr>td {
      font-size: 1.4rem;
      border: none;
      white-space: nowrap;

      img {
        height: 35px;
        width: 35px;
      }

      canvas {
        height: 30px !important;
        width: 65px !important;
      }
    }
  }
}



.tradingBotsHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: end;
  flex-flow: wrap-reverse;
  gap: 20px;

  .walletView {
    margin-bottom: 9px;
    border-radius: 32px;
    height: 50px;
    white-space: nowrap;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      height: 32px;
      margin-bottom: 20px;
    }

    @media (max-width: 573px) {
      width: 100%;
      margin-bottom: 0;
    }

    &__buttons {
      border-radius: 32px;
      border: 0;
      max-width: 140px;
      width: 100%;
      background-color: $bgderkgrey !important;
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: $darktext;
      font-weight: 600;
      padding: 6px 33px;
      height: 50px;
      cursor: pointer;
      transition: 0.3s all;

      &:first-child {
        border-radius: 12px 0px 0px 12px;
      }

      &:last-child {
        border-radius: 0px 12px 12px 0px;
      }

      @media (max-width: 1366px) {
        max-width: 100%;
      }

      @media (max-width: 767px) {
        padding: 5px 20px;
      }

      @media (max-width: 575px) {
        height: 35px;
      }

      &__active,
      &:hover {
        background: transparent linear-gradient(156deg, $darktext 0%, $baseblue 100%) 0% 0% no-repeat padding-box;
        color: $white !important;
      }
    }
  }

  &_card {
    @media (max-width: 573px) {
      width: 100%;
    }

    .portfolioCard {
      @media (max-width: 573px) {
        max-width: 100%;
      }
    }
  }
}

.ustdCoin {
  display: flex;
  align-items: center;

  .spotitems {
    display: flex;
    flex-direction: column;
    color: var(--smalp);

    b {
      color: var(--btntext);
    }
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 23px;
    margin-right: 8px;
    box-sizing: content-box !important;
  }
}

.baseblue {
  color: $baseblue;
}

.redFF593D {
  color: $redFF593D;
}

.green129D26 {
  color: $green129D26;
}


.buySellTabs {
  border-radius: 32px;
  height: 35px;
  white-space: nowrap;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    height: 32px;
  }

  &__btns {
    border-radius: 32px;
    border: 0;
    max-width: 140px;
    width: 100%;

    @media (max-width: 1366px) {
      max-width: 100%;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
      font-size: 14px;
      color: $text-80838E;
      font-weight: 600;
      background-color: #f1eff5;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      font-size: 14px;
      color: $text-80838E;
      background-color: #f1eff5;
      font-weight: 600;
    }

    @include fluid-type(320px, 1920px, 14px, 16px);
    color: $darktext;
    font-weight: 500;
    padding: 6px 33px;
    height: 40px;

    @media (max-width: 767px) {
      padding: 5px 20px;
    }

    @media (max-width: 575px) {
      height: 35px;
    }

    cursor: pointer;

    &.buySellTabsactuve {
      background: transparent linear-gradient(101deg, #2995a1 0%, #3fbcc5 100%) 0% 0% no-repeat padding-box;
      color: $white;
    }

    &--active {
      border-radius: auto 0px 0px auto;
      background: $redgradbtn;
 
      color: $white !important;
    }

    .sellRed {
      background-color: $redgradbtn;
    }
  }
}

.kycContainer {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;


  iframe {
    max-width: 650px !important;
    border-radius: 20px;

    box-shadow: 0 3px 6px -4px rgba(135, 131, 131, 0.12),
      0 6px 16px 0 rgba(145, 133, 133, 0.08),
      0 9px 28px 8px rgba(237, 232, 232, 0.1);
  }

  h1 {
    @include fluid-type(320px, 1920px, 20px, 30px);
    font-weight: 500;
    color: var(--light-text) !important;
    margin-bottom: 40px;
  }
}

.gridToggalView {
  display: flex;
  gap: 16px;

  .gridToggalbtn {
    cursor: pointer;

    svg path {
      fill: red;
    }
  }
}



.app-view-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tooltip-open {
  border-color: $baseblue !important;
}

.ant-slider-track {
  background-color: $baseblue !important;
}

.ant-slider-mark-text {
  color: var(--smalp) !important;
}

.ant-slider-handle {
  border: 2px solid $baseblue !important;
}

.dot {
  width: 6px;
  height: 6px;
  background: $baseblue;
  vertical-align: middle;
  border-radius: 4px;
  display: -webkit-inline-box;
}

.copytradingmodal {
  .ant-modal-content {
    padding: 17px 38px 32px 26px !important;
  }

  .advancedOption {
    color: var(--smalp);
  }
}

.editButton {
  color: $baseblue !important;
  vertical-align: middle;
}

.buyOne {
  width: 34px;
  height: 24px;
  text-align: center;
  color: $baseblue;
  background: #1ea0ab33 0% 0% no-repeat padding-box;
}

.sellOne {
  width: 34px;
  height: 24px;
  text-align: center;
  color: $redFF593D;
  background: #ff593d33 0% 0% no-repeat padding-box;
}

.selling {
  color: $redFF593D !important;
}

.runningOrder {
  .ant-tabs-tab {
    font-size: 14px !important;
    font-weight: 600;
    background: #f1eff5 0% 0% no-repeat padding-box;
    border-radius: 12px;
    color: $darktext !important;
  }

  .ant-tabs-nav-wrap {
    border-radius: 20px;
  }

  .ant-tabs-nav {
    font-size: 14px !important;
    font-weight: 600;

    &::before {
      border: none !important;
    }
  }

  .Completed {
    background: var(--gridCard) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    padding: 24px 36px;
    margin: 16px 0;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
  }

  .ant-tabs-nav-list {
    font-size: 14px !important;
    font-weight: 600;
    width: 100%;
    background: var(--setingCardBg);
    box-shadow: 0px 3px 16px #00000017;
    border-radius: 20px;
    padding: 13px 16px;
    border: none;
    gap: 16px;

    .ant-tabs-ink-bar {
      background: none !important;
    }

    .ant-tabs-tab-active {
      background: transparent linear-gradient(157deg, $darktext 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;
      border-radius: 12px;
      font-size: 14px;
      border-bottom: none;

      .ant-tabs-tab-btn {
        font-weight: 600;
        color: $white !important;
      }
    }
  }
}

.tradetab .ant-tabs-tab {
  padding: 14px 30px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: grey !important;
}

.nextmodal_tabcss {

  .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-top .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
  }

  .ant-tabs-nav-list {
    border-radius: 10px;
    overflow: hidden;

    .ant-tabs-tab.ant-tabs-tab-active {
      background: transparent linear-gradient(92deg, #2a688b 0%, #1ea0ab 100%) 0% 0% no-repeat padding-box;

      .ant-tabs-tab-btn {
        color: #fff !important;
      }
    }

    .ant-tabs-tab-btn {
      user-select: none;
      text-align: center;
      width: 100%;
    }

    .ant-tabs-tab {
      background: #f1eff5 0% 0% no-repeat padding-box;
    }
  }

  .ant-tabs-nav {
    &:before {
      border-bottom: 0 !important;
    }
  }

  h1 {
    @include fluid-type(320px, 1920px, 20px, 24px);
    font-weight: 600;
    letter-spacing: 0px;
    margin-top: 50px;
    color: var(--headerText);
  }

  .tab_ownAcc {
    p {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: var(--headerText);
      text-align: left;
      margin-top: 20px;
    }

    h1 {
      color: var(--headerText);
      text-align: left;
      margin-top: 20px;
    }

    .modalBtn {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      >button {
        flex: 0 48%;
      }
    }

    .ant-slider-with-marks {
      margin-bottom: 55px;
    }
  }

  .ant-tabs-tab {
    min-width: 175px;
    width: 100%;

    @media (max-width: 480px) {
      min-width: 125px;
    }

    @media (max-width: 350px) {
      min-width: 100px;
    }
  }

  .confirmbtn {
    button {
      width: 100%;
    }
  }
}

.ant-collapse {
  border: none !important;
  border-radius: 20px !important;

  .ant-collapse-header-text {
    color: var(--btntext);
  }

  .ant-collapse-content {
    background-color: var(--gridCard) !important;
    border-top: 0;
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-expand-icon {
    position: absolute;
    right: 0;
    color: var(--btntext);

    .ant-collapse-arrow {
      margin: 0 !important;

      svg {
        transform: rotate(90deg);
      }
    }
  }

  .ant-collapse-item-active {
    border-radius: 0 !important;

    .ant-collapse-expand-icon {
      .ant-collapse-arrow {
        svg {
          transform: rotate(270deg) !important;
        }
      }
    }


  }

  .ant-collapse-item {
    border-radius: 17px !important;
    border: none !important;
    background-color: var(--gridCard) !important;
  }

  .ant-collapse-content-box {
    background-color: var(--gridCard) !important;
    padding: 0 !important;

    tr {
      background-color: var(--collpasedata);

      :first-child {
        text-align: left;
        color: $text-80838E;

        :nth-child(1) {
          @include fluid-type(320px, 1920px, 14px, 16px);
          color: $darktext;
        }
      }

      :last-child {
        color: var(--btntext);
      }
    }
  }
}

.underLine {
  border-bottom: 1px dashed #181A20;
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: $text-80838E;
}

.orderBookTab {

  .ant-tabs-nav {
    position: absolute !important;
    top: 11px;
    left: 36%;

    &::before {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    gap: 12px;

    .ant-tabs-tab {
      opacity: 0.5;
    }

    .ant-tabs-tab-active {
      opacity: initial;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    padding: 0 !important;
  }


  .asks {
    .askHeight {
      height: 486px;
      overflow-y: auto;
    }
  }
}

.profileinputs {
  .input_imgcontent {
    top: 70% !important;
    cursor: pointer;
  }
}

.devicemanagement {
  padding: 30px 38px 30px 50px;
}

.deleteModal .ant-modal-content {
  border-radius: 20px;
  text-align: center;
  background: var(--modalBg);

  p {
    color: var(--headerText);
  }

  .ant-btn {
    background: var(--themegraybtn);
    color: var(--btntext);
    border-radius: 8px;
    border: none;
    padding: 0 40px;
  }

  .ant-btn-primary {
    background: var(--themebgbtn) !important;
    color: $white;
  }

  .ant-modal-footer {
    padding: 20px 16px;
    text-align: center;
    border-top: 1px solid var(--bordercolor);
  }
}

.delButton {
  padding-left: 0 !important;
}

.ant-empty-description {
  color: var(--smalp);
}

.disableLink {
  pointer-events: none;
  color: #938a8a !important;
}

.transak_widget {
  .transak_modal-overlay {
    background-color: #000000d6;
    z-index: 1001;
  }

  .transak_modal {
    z-index: 1002 !important;
  }
}

.buySellError {
  padding-bottom: 10px;
  text-align: center;
}

.themeIcon {
  margin-left: 24px;
  cursor: pointer;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: unset !important;
}

.exchange-table {
  table {
    thead {
      tr {
        th {
          background: var(--gridCard);
        }
      }
    }
  }
}

.sellSelectedTab {

  .ant-tabs-tab-active,
  .redBtn {
    background: transparent linear-gradient(100deg, #ed1e59 0%, #ef4b31 100%) 0% 0% no-repeat padding-box !important;
  }
}

.buySellInnerMargin {
  margin-top: 90px;

  @media (max-width: 1199px) {
    margin-top: 0;
  }
}

.tableheight {
  @media (max-width: 767px) {
    .ant-table-body {
      max-height: calc(100vh - 240px) !important;
      height: 100% !important;
    }
  }
}

.OpenOrderHeight {
  .ant-table {
    height: 350px;
  }

  .ant-table-content {
    @media (min-width: 767px) {
      max-height: 332px;
    }
  }
}

.favorites {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 5px;

  &--active {
    svg path {
      fill: $CommonButton;
    }
  }
}

.highLowPrice {
  .anticon-arrow-up {
    svg {
      fill: #1ea0ab;
    }
  }

  .anticon-arrow-down {
    svg {
      fill: #ff593d;
    }
  }
}

.slick-track {
  margin-left: unset !important;
}

.slick-slide {
  >div {
    margin: 0px 10px !important;
  }
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  &::before {
    background: #235f94 !important;
    border-radius: 30px;
  }
}

.trading_rule {
  .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    border-radius: 12px;
    overflow: hidden;
    padding: 0px;


    >.ant-dropdown-menu-item {
      padding: 0 !important;
    }

    >.ant-dropdown-menu-title-content {
      padding: 0;
    }

    .ant-dropdown {
      ul {
        background-color: transparent !important;

        li {
          background-color: transparent !important;
        }
      }
    }
  }
}

.step_refferal {
  .ant-steps-vertical>.ant-steps-item .ant-steps-item-description {
    padding-bottom: 50px !important;
    color: var(--headerText) !important;
  }

  .ant-steps-item-title {
    font-weight: 600;
    color: var(--headerText) !important;
  }

  .ant-steps-item-title {
    padding-bottom: 6px !important;
  }
}

.rewardHisTable {
  .ant-table {
    background: transparent !important;
    background-color: transparent !important;
    height: 271px;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.0901960784) !important;
  }

  .ant-table-thead {
    background-color: transparent !important;
  }

  .ant-table.ant-table-empty {
    overflow: hidden !important;
  }

  .ant-table-tbody {
    max-height: 225px !important;
    height: 225px !important;
    overflow: auto;
  }
}

.totalEarning {
  button {
    margin-top: 50px;
  }
}

.btn_space {
  margin-top: 40px;
}

.rewardHistory {
  .ant-select-selection-placeholder {
    line-height: 30px !important;
  }

  .ant-select-item {
    line-height: 33px !important;
  }

  .ant-picker,
  .ant-select {
    background-color: var(--homeCard) !important;
  }
}

.theme-dark {
  .ant-menu-item.ant-menu-item-selected {
    svg {
      path {
        stroke: #1ea0ab;
        fill: var(--headerText1) !important;
      }
    }
  }
}


.cryptotable {
  .posNegImg {
    width: 9px !important;
  }

  .positive {
    color: #0ecb81;
  }

  .negative {
    color: #ff4d4f;
  }
}

.theme-dark {
  .rules {
    background: #1f2638 !important;
  }

  .ant-picker-panel-container {
    background: #181A20 !important;

    >button {
      color: #fff !important;
    }

    .ant-picker-content {
      th {
        color: #fff !important;
      }
    }

    .ant-picker-cell {
      color: grey !important;
    }

    .ant-picker-cell-in-view {
      color: #fff !important;

      &:hover {
        color: #181A20 !important;
      }
    }

    .ant-picker-month-btn,
    .ant-picker-year-btn {
      color: #fff;
    }

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn {
      color: grey !important;
    }

    td {
      color: #fff !important;
    }
  }
}

.theme-light {
  .dropoptiondesign {

    .ant-dropdown-menu,
    .ant-dropdown-menu-item {
      background-color: #fff !important;

      span {
        background-color: #fff !important;
      }
    }
  }
}

.dropoptiondesign {

  .ant-dropdown-menu,
  .ant-dropdown-menu-item {
    background-color: transparent !important;
  }
}

.withoutParent {
  .tab_ownAcc {
    p {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: var(--headerText);
      text-align: left;
      margin-top: 10px;
    }

    h1 {
      @include fluid-type(320px, 1920px, 20px, 24px);
      font-weight: 600;
      text-align: left;
      letter-spacing: 0px;
      margin-top: 10px;
      color: var(--headerText);
    }

    .modalBtn {
      display: flex;
      gap: 20px;
      margin-top: 30px;

      >button {
        flex: 0 48%;
      }
    }
  }
}

.gridSelect {
  height: 40px;

  input {
    border: 0;
    height: 40px;
  }

  .ant-input-group-addon {
    border: 0 !important;
  }

  .ant-select-focused.ant-select-open {
    box-shadow: none !important;
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
  }
}

.orderdHistoryTrans {
  display: flex;
  gap: 20px;
  margin-left: 10px;
}

.orderdHistoryTrans .label {
  font-size: 14px;
}

.grid_tab {
  .ant-tabs-nav {
    margin-bottom: 10px !important;
  }
}


.exchange-table.history-table {
  height: 332px;
  overflow: hidden;

  @media (max-width: 767px) {
    max-width: 767px;
    width: 767px;
    height: 472px;
    overflow-x: scroll !important;

    td {
      padding: 5px 15px !important;
    }
  }
}

.OpenOrderHeight {
  .ant-empty.ant-empty-normal {
    height: 214px;
    padding-top: 70px;
  }
}

.tableheight.custom-table.exchange-table.history-table {
  .ant-empty.ant-empty-normal {
    padding-top: 60px;
  }
}

.tradeOrderTable {
  .ant-table-content {
    height: 332px;
    overflow: auto;
  }

  .ant-table-tbody>tr>td {
    padding: 5px 16px;
  }

  .ant-empty.ant-empty-normal {
    height: 196px;
    padding-top: 80px;
  }
}

.fc-widget-small {
  bottom: 63px !important;
}

.landingHeaderLeft {
  margin-left: 15px;
}

.registerTrade {
  color: var(--light-text);
}

.notificationStyle {
  @media (min-width:767px) {
    height: 40px;
    width: 40px;
  }

  .ant-avatar-string {
    line-height: 35px !important;
    position: static !important;
  }

  .settingDropDown.notify {
    background: transparent !important;
  }

  .ant-avatar {
    overflow: unset !important;
  }

  a {
    height: 40px;
    width: 40px !important;
    background: var(--themebgbtn);
    border-radius: 40px !important;
    margin-left: 0 !important;

    &:hover,
    &:active,
    &:focus-visible,
    &:focus {
      background: var(--themebgbtn) !important;
    }

    img {
      @media (max-width: 380px) {
        height: 13px;
        width: 13px;
      }
    }

    .ant-badge-count {
      top: -8px;
      right: -8px;

      @media (max-width: 380px) {
        top: -7px;
        right: -2px;
      }
    }
  }
}

.OpenOrderHeight {
  .ant-table-tbody {
    td {
      padding: 5px !important;
    }
  }
}

.mobTabs {
  .ant-tabs-nav-wrap {
    overflow: unset !important;
  }

  .ant-tabs-nav-list {

    @media (max-width:350px) {
      padding: 0px 20px;
    }
  }

  .open_AllOrderTab {
    .ant-tabs-content-holder {
      @media (max-width: 767px) {
        overflow-x: auto !important;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    .cstmMob {
      color: #1ea0ab;
    }
  }

  .cstmMob {
    color: #897ea3;
  }
}

.notlogged_leang {
  height: 40px;
}

.ant-btn.cstmbg {
  background: transparent linear-gradient(20deg, #1ea0ab 0%, rgba(57, 39, 101, 0.7098039216) 100%) 0% 0% no-repeat padding-box !important;
  display: block !important;
  margin-bottom: 10px !important;
}

.ant-btn.cstmbg.nologinBtn-exe {
  background: transparent !important;
  color: #392765 !important;
  padding: 7px 0px;
}

.settingset {
  max-width: 410px;
  width: 100%;
 
  .ant-dropdown-menu {
    width: 100% !important;
    max-width: 410px !important;
  }

  ul {
    padding-top: 0px !important;
    padding-bottom: 10px !important;

    .notificationdrop {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      padding: 15px !important;
      text-align: center;
      margin-bottom: 5px !important;
      color: var(--light-text);
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #d9d9d9 !important;


      a {
        color: #1EA0AB;
      }
    }

    .ant-dropdown-menu-item {
      padding: 15px !important;
    }

    .unread {
      p {
        color: #1EA0AB !important;
      }
    }

    li {
      border-bottom: 1px solid #d9d9d9 !important;

      &:hover {
        border-radius: 0;
      }

      &:last-child {
        border: 0 !important;
      }
    }

  }
}

.transactionHistory {

  .ant-tabs-tab {
    padding: 14px 20px !important;
  }

}

.blueTabs.portTab {
  .fundingtable {
    .portfolioTable {
      .ant-table-content {
        height: calc(100vh - 470px);
        overflow: auto !important;
      }
    }

  }

}



.scrollportfolio {

  height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
}

.darkthemeicon {
  display: none;
}

.exchangePage-withoutLogin {
  .lighttheme {
    display: none;
  }

  .darkthemeicon {
    display: block !important;
    width: 100px;
  }
}

.ant-btn {
  transition: none !important;
}

.greenText {
  color: green;
  font-weight: 600;
}

.redText {
  color: red;
  font-weight: 600;
}

.scrollabel_View {
  .ant-table-content {
    height: 400px;
    overflow-y: auto;
  }
}

.ant-picker-dropdown {
  .ant-picker-header-view {
    color: var(--headerText1);
  }

  .ant-picker-header button {
    color: var(--headerText1);
  }

  .ant-picker-header {
    border-bottom: 1px solid var(--borderclr);
  }



  .ant-picker-cell {
    color: var(--headerText1);
  }

  .ant-picker-cell-disabled {
    opacity: 0.3;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: $link;
    color: #FFF;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: $link;
  }

  .ant-picker-today-btn {
    color: $baseblue;
  }

  .ant-picker-panel .ant-picker-footer {
    border-top: 1px solid var(--borderclr);
    background: var(--seetingCardBg) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--borderclr);
  }

  .ant-picker-content {
    thead tr th {
      background-color: #1ea0ab;
      color: #fff;
    }
  }
}

.ant-picker-panel-container {
  .ant-picker-panel {
    background: var(--seetingCardBg) !important;
    border: 0;
  }
}

.ant-modal-wrap {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
}

.ant-tabs-tab-btn {
  &:focus {
    color: var(--light-text);
  }

}

.textDecoration {
  .ant-tabs-tab-btn {
    &:focus {
      color: var(--light-text) !important;
    }

  }
}

.blueesback {
  .ant-tabs-tab-btn {
    &:focus {
      color: #fff;
    }

  }
}

.noNotification {
  font-weight: 500 !important;
}

.theme-dark {
  .modalapp {
    .ant-modal-body {
      background: #131b2a;
    }
  }
}

.theme-light {
  .modalapp {
    .ant-modal-body {
      background: #fff;
    }
  }
}

.modalapp {
  .anticon {
    svg {
      color: var(--light-text);
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    border-radius: 10px;
  }

  .ant-modal-content {

    color: var(--light-text);

    p {
      font-size: 25px;
    }

    .modalbtnSet {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 150px;
        color: #fff;
        background: var(--themebgbtn) !important;
      }
    }

  }
}


.theme-light {
  .marketTables {
    table {
      .ant-table-thead {
        background-color: #e8f1f9;
      }
    }
  }
}