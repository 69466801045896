@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";



.Buy {
  padding: 25px 20px 22px 20px;

  @media (max-width: 991px) {
    padding: 20px;
  }

  &_row {
    justify-content: center;
    margin-bottom: 10px;
    flex-flow: wrap;
    align-items: center;
    gap: 10px;

    @media (max-width: 767px) {
      align-items: center;
    }

    p {
      border: none;
      text-align: center;
      color: $text-80838E;

      @media (max-width: 767px) {
        margin-top: 10px;
      }

      span {
        width: 32px;
        background-color: $bg-light-gray-F3F3F3;
        height: 32px;
        border-radius: 50%;
        border: 1px solid white;
        padding-bottom: 5px;
        display: inline-block;

        img {
          padding: 7px;
        }
      }
    }

  }

  &_text {
    margin: auto;
    padding: 10px 0px;
    color: $text-80838E;
  }

  div+a {
    text-align: center;
    display: block;
    color: $baseblue;
    background-color: $bg-light-blue-E9F5FF;
    width: 196px;
    padding: 15px 20px;
    margin: auto;
    border-radius: 30px;
    margin: 25px auto 28px;

    @media (max-width: 767px) {
      font-size: 12px;
      padding: 10px 10px;
      width: auto;
    }

    img {
      margin-right: 8px;
      width: 30px;
      height: 25px;
    }
  }

  &_btn {
    ul {
      padding: 0;
      margin-bottom: 0;

      li {
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 17px;
        list-style: none;
        border-bottom: 1px solid $border-light-gray-F3F3F3;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        @media (max-width: 767px) {
          padding: 12px;
          margin: 0;
        }

        &:last-child {
          border-bottom: none;
          position: relative;
        }

        span {
          display: inline-block;
          white-space: nowrap;
          color: var(--btntext);

          @media (max-width: 767px) {
            font-size: 12px;
          }

          &:first-child {
            text-align: left;
            color: $text-80838E;
          }

          &:last-child {
            text-align: right;
            margin-left: 10px;
            color: $text-80838E;

            @media only screen and (min-width: 1200px) and (max-width: 1440px) {
              width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

          }
        }
      }
    }

    &_icon {
      width: 23px;
      margin-right: 6px;
    }
  }

  hr {
    margin: 0;
    border: 0.2px solid red;
  }

  &_btnMain {
    button {
      width: 100%;
      margin: 36px 0 16px;
    }
  }

  &_amount {
    display: flex;
    justify-content: space-between;

    p {
      span {
        color: var(--btntext);
        font-weight: 600;
      }
    }

    label {
      color: $text-80838E;
    }
  }

  div>span {
    color: $text-80838E;
  }
}

.btnList {
  &_heading {
    padding: 25px 20px;
    position: sticky;
    top: 0;
    background: var(--cardBackground);
    z-index: 9;
    border-radius: 20px 20px 0 0;

    @media (max-width: 767px) {
      padding: 20px 15px;
    }
  }

  &_top {
    button {
      border: none;
      background-color: transparent;
      float: left;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 !important;
      width: auto !important;
    }

    h5 {
      text-align: center;
      @include fluid-type(320px, 1920px, 12px, 16px);
      font-weight: 600;
      color: var(--btntext);
    }
  }

  ul {
    height: 600px;
    overflow-y: scroll;

    li {
      list-style: none;
      margin-bottom: 36px;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }

      img {
        display: block;
        float: left;
        width: 44px;
        height: 44px;
        margin-right: 8px;

        @media (max-width: 767px) {
          width: 34px;
          height: 34px;
        }
      }

      h6 {
        @include fluid-type(320px, 1920px, 12px, 16px);
        font-weight: 600;
        line-height: initial;
        padding-top: 4px;
        color: var(--whiteblack);

        span {
          // @include fluid-type(320px, 1920px, 10px, 14px);
          color: $text-80838E;
          font-weight: 400;

          // line-height: 19px;
          &:last-child {
            padding-left: 8px;
          }
        }
      }
    }
  }

  &_content {
    overflow: hidden;
    // height: 458px;
  }
}

.PayWith {
  &_body {
    padding: 40px;

    @media (max-width: 767px) {
      padding: 10px 15px 20px 15px;
    }

    &_list {
      align-items: flex-start;
      justify-content: space-between;
      background-color: var(--bankcardBg);
      padding: 20px;
      border-radius: 14px;
      margin-bottom: 20px;
      cursor: pointer;

      p {
        color: $text-80838E;

        span {
          color: $baseblue;
        }
      }

      &_dflex {
        display: flex;
        align-items: flex-start;

        img {
          max-width: 40px;
          margin-right: 8px;
        }

        h6 {
          @include fluid-type(320px, 1920px, 12px, 16px);
          font-weight: 600;
          margin-bottom: 0;
          color: var(--whiteblack);

          span {
            margin-top: 10px;
            color: $text-80838E;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.Asset {
  &_body {
    padding: 10px 20px 10px 20px;

    @media (max-width: 991px) {
      padding: 10px 15px 20px;
    }

    &_scrollitem {
      overflow-y: scroll;
      // max-height: 600px;
      height: 100%;

      .amount {
        font-weight: 600;
        color: $baseblue;
        @include fluid-type(320px, 1920px, 20px, 35px);
        margin-bottom: 30px;
        margin-top: 10px;
        text-align: center;
      }

      .currencydata {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        label {
          color: $text-80838E;
        }

        h4 {
          text-align: right;

          img {
            width: 25px;
            height: 25px;
          }
        }

        &:last-child {
          padding-bottom: 40px;
        }
      }

      .note {
        padding-top: 50px;
        font-weight: 600;
      }
    }

    &_list {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 13px 20px;
      border-radius: 14px;
      cursor: pointer;

      &.active {
        background-color: var(--hoverWhite);
      }

      &:hover,
      .active {
        background-color: var(--hoverWhite);
      }

      &_imgSec {
        h6 {
          line-height: initial;
          color: var(--headerText);
          font-weight: 600;
          @include fluid-type(320px, 1920px, 12px, 16px);
          display: flex;
          align-items: center;

          .shortName {
            font-weight: 400;
            color: $text-80838E;
            @include fluid-type(320px, 1920px, 12px, 14px);
          }
        }

        img {
          float: left;
          margin-right: 8px;
          height: 44px;
          width: 44px;

          @media (max-width: 767px) {
            width: 30px;
            height: 30px;
          }
        }
      }

      p {
        margin-bottom: 0;
        line-height: initial;
        font-weight: 600;
        @include fluid-type(320px, 1920px, 12px, 16px);

        span {
          font-weight: 400;
          color: $text-80838E;
          @include fluid-type(320px, 1920px, 12px, 14px);
        }
      }

      &_check {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p {
          text-align: right;
          color: var(--headerText);
        }
      }
    }

    &_search {
      margin-bottom: 17px;
      position: relative;

      input {
        border-radius: 25px !important;
        margin: 0 !important;
        padding-left: 45px;
        background-color: var(--searchinputclr) !important;
        border: 1px solid var(--bordercolor) !important;
        outline: 0 !important;
        box-shadow: 0;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        z-index: 1;
        width: 15px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../Assets/Images/SearchIcon.svg");
      }
    }
  }
}

/*custom tab...... scss*/

.positionedContent {
  position: absolute;
  inset: 0;
  z-index: 9;
  background: var(--cardBackground);
  border-radius: 20px;
  overflow: hidden;

  @media (max-width:1199px) {
    margin: 0 20px;
  }

  ul {
    padding-left: 15px;
  }

  // overflow-y: auto;
}

.pdlr {
  padding: 50px 0px 22px 0px;
}

.buy {
  padding: 32px 20px 14px 20px;

  h6,
  p {
    padding-bottom: 6px;
  }

  &_input {
    padding: 12px 20px;
    border-radius: 14px;
    background-color: var(--cardBackground);
    box-shadow: var(--shadow);
    margin: 32px 0 24px;

    h6 {
      display: flex;
      justify-content: space-between;
    }

    p {
      @media only screen and (min-width: 1200px) and (max-width: 1440px) {
        max-width: 200px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  p+h6 {
    padding-top: 28px;
  }
}

.ant-tabs-tab-active[aria-selected="false"] {
  background-color: #ff0000;
}