@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$baseFontRegular: "Open Sans", sans-serif;
$baseFontBold: "Open Sans", sans-serif;
// $white: #000000;
$grayLightTxt: #848e9c;
$lightbgyellow: #fff4c7;
$darktext: #392765;
$baseblue: #1ea0ab;
$baseblue1: #4e85fb;
$base-hover: #40a9ff;
$bordergreen: #a5d9dd;
$bordergrey: #d7d4e0;
$border-FFFFFF33: #ffffff33;
$pendingtext: #e5862f;
$portfoliobg: #f7f9fd;
$borderdarkgrey: #80838e;
$bgderkgrey: #f3f3f3;
$grey-707070: #707070;
$text-80838E: #897ea3;
$bg-icon-80838E: rgba(128, 131, 142, 0.1);
$greenbuy: #40bdc6;
$redsell: #ef4c30;
$btnred: #ef4539;
$green-098551: #098551;
$tableborders: #00000012;
$bg-light-FBFCFF: #fbfcff;
$bg-EDF4F5: #edf4f5;
$bgrewardata: #f8f8f8;
$bg-DEEBF0: #deebf0;
$bgblue-ffffff4a: #ffffff4a;
$bg-light-gray-F3F3F3: #f3f3f3;
$dissableColor: #f3f3f3c9;
$xValueColor: #1EA0AB;
$xValueBorder: #E1F3F4;

$bg-light-blue-E9F5FF: #e9f5ff;
$bg-totalearning: #bdcef0;
$bg-FFFFFF9E: #ffffff;
$bg-totalearning1: #fafcff00;
$border-light-gray-F3F3F3: #f3f3f3; // Dual Theme control mode start
$staticBorder: $bordergrey;
$CommonButton: $baseblue;
$checkboxparagraph: black;
$portfolioHead: #3927650f;
$btnTextLight: $text-80838E;
$link: #014cec;
$lightblue-F5F8FF: #eefeff;
$white: white;
$white-FFFFFFCC: #ffffffcc;
$red: #cf0038;
$red-CF304A: #ff593d;
$tabletext: #1b1c1e;
$bg-DCEAEF:#DCEAEF;
$green: #12b764;
$tabletext: #1b1c1e;
$tabletext: #1b1c1e;
$bg-F3F2F6: #f3f2f6;
$blue-F1F5FF: #f1f5ff;
$blue-F3F7FF: #f3f7ff;
$greengradbtn: transparent linear-gradient(101deg, #2995a1 0%, #3fbcc5 100%) 0%
  0% no-repeat padding-box;
$redgradbtn: transparent linear-gradient(100deg, #ed1e59 0%, #ef4b31 100%) 0% 0%
  no-repeat padding-box;
$bgblue: transparent linear-gradient(20deg, #392765b5 0%, #1ea0ab 100%) 0% 0%
  no-repeat padding-box;
$invertbg: transparent linear-gradient(20deg, #1ea0ab 0%, #392765b5 100%) 0% 0%
  no-repeat padding-box;
$parameterbg: transparent linear-gradient(180deg, #fdfcff 0%, #ffffffba 100%) 0%
  0% no-repeat padding-box;
$copytradebg: #e5edf4 0% 0% no-repeat padding-box;
$redFF593D: #ff593d;
$gradientbtn: transparent
  linear-gradient(176deg, rgba(79, 63, 118, 1) 0%, #1ea0ab 100%) 0% 0% no-repeat
  padding-box;
$blueEBF9FB: #ebf9fb;
$green129D26: #129d26;

// Dual Theme control mode start

// $themes: (
//   lightTheme: ("securityBtn": #dfe3ec,
//     "darkWhite": #181A20,
//     "darkWhiteReverse": #fff,
//   ),
//   darkTheme: ("securityBtn": #282c34,
//     "darkWhite": #fff,
//     "darkWhiteReverse": #181A20,
//   ),
// );
