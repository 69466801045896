@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.input_textimg {
  position: relative !important;

  label {
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 400;
    display: block;
    color: var(--headerText);
    padding-bottom: 8px;
  }

  input {
    color: var(--headerText);
    &:hover,
    &:active {
      border-color: $baseblue !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: var(--homeCard) !important;
      border: 1px solid var(--bordercolor);
      color: var(--headerText);
      // &:hover {
      //   background-color: $darktext !important;
      // }
    }

    &.marginTopNone {
      margin-top: 0;
    }
  }
  .input_amount {
    border: 1px solid var(--bordercolor);
    opacity: 1;
    width: 100%;
    height: 40px;
    box-shadow: none;
    position: relative;
    background-color: var(--homeCard);
    border-radius: 10px;

    &.onlyCurrency {
      padding-right: 50px;
    }
    &::placeholder {
      color: $text-80838E;
    }
  }

  .input_imgcontent {
    color: $text-80838E;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    .width_custm_input {
      width: 100px;
    }

    img {
      margin-right: 10px;
    }

    p {
      margin-bottom: 0px;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .paddingRight {
    padding-right: 50px;
  }
}
