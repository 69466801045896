@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.selectdatefix {
  position: relative;
}

.customDatePicker {
  width: 100%;
  border-radius: 5px !important;
  opacity: 1;
  width: 100%;
  height: 40px;
  box-shadow: none;
  position: relative !important;
  background-color: transparent !important;
  font-size: 14px;
  border-color: var(--bordercolor) !important;
  border-radius: 50px !important;
  // margin-bottom: 10px !important;

  &:hover,
  &:focus,
  &:focus-within,
  &:focus-visible {
    border: 1px solid $staticBorder;
    outline: none !important;
    box-shadow: unset !important;
  }

  .ant-picker-clear {
    background: var(--homeCard);
  }

  .anticon {


    svg {
      path {
        fill: var(--headerText);
      }
    }
  }
}

.datepickerSimple {
  border-color: $bordergrey !important;

  box-shadow: none !important;

  pre input {
    color: $checkboxparagraph;
  }

  &:hover {
    border: 1px solid $baseblue;
    outline: none;
  }
}

.ant-picker {
  background-color: transparent;

  .ant-picker-input>input {
    color: var(--whiteblack);
  }
}

.datepickereasy {
  border: none;
  background-color: $white;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: $CommonButton !important;
}

.datepickerAdvance,
.datepickerTime {
  border: 1px solid;

  input {
    // color: $whiteblackBase !important;
  }

  border-color: $staticBorder !important;

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within {
    border-color: $CommonButton !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .ant-picker-suffix {
    svg path {
      // fill: $grayLightTxt;
    }
  }
}

.label {
  letter-spacing: 0px;
  // color: $checkboxparagraph;
  opacity: 1;
  width: 100%;
  text-align: left;
  @include fluid-type(320px, 1920px, 12px, 14px);
  display: flex;
  padding-bottom: 8px;
  font-weight: 500;
  color: var(--headerText);
}