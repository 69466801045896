@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.inputLayout {
  position: relative;
  // margin-bottom: 10px;

  .input_custum {
    border: 1px solid var(--bordercolor);
    border-radius: 8px;
    opacity: 1;
    width: 100%;
    height: 40px;
    box-shadow: none;
    position: relative !important;
    background-color: var(--homeCard);
    padding-left: 16px;
    border-color: $bordergrey;
    color: var(--whiteblack);

    &:focus {
      box-shadow: none;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      // border-color: $CommonButton !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &.innerTxtInput {
      padding-right: 60px;
    }

    &.currencyInput {
      padding-right: 60px;
      height: 40px;
      color: $text-80838E;
      background-color: transparent;
      border: 1px solid var(--bordercolor);

      @media (max-width: 585px) {
        height: 35px;
      }

      &::placeholder {
        color: var(--whiteblack) !important;

        opacity: 1;
      }
    }


    &.referralInput {
      padding-right: 60px;
      height: 40px;
      background-color: $white;
      color: $borderdarkgrey;
      margin-bottom: 24px;

      &::placeholder {
        color: $borderdarkgrey;
      }

      @media (max-width: 585px) {
        height: 40px;
      }
    }
  }

  .inputInnerTxt {
    padding-right: 100px;
  }

  .eyeIcon {
    input {
      background: transparent;
      // color: $whiteblackBase;
    }

    svg path {
      fill: #8d8d8d;
    }
  }

  .innerTxt {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #fcd535;
    cursor: pointer;
  }

  .currency {
    position: absolute;
    right: 1px;
    top: 25px;
    height: 38px;
    width: 60px;
    text-align: center;
    transform: translateY(-50%);
    color: $darktext;
    font-size: 14px;
    cursor: pointer;
    background-color: $bgderkgrey;
    border-radius: 0px 8px 8px 0px;
    padding-top: 7px;
    // border-left: 1px solid $borderdarkgrey;

    @media (max-width: 585px) {
      top: 22px;
      height: 32.5px;
    }
  }

  .inputInnerLargeTxt {
    padding-right: 185px;
  }

  .innerTxtImg {
    position: absolute;
    right: 16px;
    top: 55%;
    // transform: translateY(-50%);
    color: #555863;
    display: flex;
    align-items: center;

    svg {
      margin-left: 10px;
    }
  }

  .inputsearch_ryt {
    padding-right: 45px;
    border: 0;
    background-color: #f5f5f5;
    border-radius: 24px;
  }

  .searchImg {
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
  }

  .searchimgryt {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .referralimgryt {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .label {
    letter-spacing: 0px;
    // color: $titlehead;
    opacity: 1;
    width: 100%;
    text-align: left;
    font-weight: 500;
    @include fluid-type(320px, 1920px, 12px, 14px);
    display: flex;
    color: var(--headerText);
  }

  .ant-input-affix-wrapper {

    // color: $darktext;
    &:focus,
    &:hover {
      border: 1px solid $baseblue !important;
    }
  }
}

.inputLayout {
  .inputSearch {
    border: 1px solid var(--bordercolor);

    &::placeholder {
      color: var(--whiteblack) !important;
    }

    padding-left: 43px;
    height: 40px;
  }
}